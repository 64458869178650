export enum GeneralEnum {

  paginationLimit = 10,

  // local
  // baseUrl = 'http://localhost:8000'


  // prod
  baseUrl = 'https://nr-api.nowrecharge.com.ng'
}
