import {GeneralUtil} from '../util/general-util';

export class InitWalletCredit {
  public transactionId: string;
  public totalAmount: number;

  public mapToEntity(data: any): void {
    if (GeneralUtil.isValidJSON(data)) {
      this.transactionId = data.transactionId;
      this.totalAmount = data.totalAmount;
    }
  }
}
