<div class=" pt-32pt pt-sm-64pt pb-32pt">
  <div class="container-fluid page__container">
    <form #registrationForm="ngForm" novalidate (ngSubmit)="registerUserAction(registrationForm)" class="col-md-5 p-0 mx-auto">

      <div class="form-group">

        <div class="alert alert-accent" role="alert" *ngIf="notification != null">
          <div class="d-flex flex-wrap align-items-start">
            <div class="flex"
                 style="min-width: 180px">
              <small class="text-black-100">
                {{notification}}
              </small>
            </div>
          </div>
        </div>

        <label class="form-label" for="email">First name:</label>

        <input id="fname" type="text" class="form-control" name="fname" required
               #fname="ngModel" [(ngModel)]="registerUserDto.firstName"
               [class.is-invalid]="fname.invalid && fname.touched"
               placeholder="Your first name">
      </div>

      <div class="form-group">

        <label class="form-label" for="email">Last name:</label>

        <input id="lname" type="text" class="form-control" name="lname" required
               #lname="ngModel" [(ngModel)]="registerUserDto.lastName"
               [class.is-invalid]="lname.invalid && lname.touched"
               placeholder="Your last name">
      </div>

      <div class="form-group">

        <label class="form-label" for="email">Email:</label>

        <input id="email" type="email" class="form-control" name="email" required
               #email="ngModel" [(ngModel)]="registerUserDto.email" pattern="^[^@]+@[^@]+\.[^@]+$"
               [class.is-invalid]="email.invalid && email.touched"
               placeholder="Your email address ...">
      </div>

      <div class="form-group">

        <label class="form-label" for="email">Phone:</label>

        <input id="phone" type="tel" class="form-control" name="phone" required
               #phone="ngModel" [(ngModel)]="registerUserDto.phone"
               [class.is-invalid]="phone.invalid && phone.touched"
               placeholder="Your phone number ...">
      </div>


      <div class="form-group">
        <label class="form-label" for="password">Password:</label>

        <input id="password" type="password" class="form-control" minlength="5" required
               name="password" #password="ngModel" [(ngModel)]="registerUserDto.password"
               [class.is-invalid]="password.invalid && password.touched"
               placeholder="Your password ...">
      </div>
      <div class="text-center">
        <button class="btn btn-accent" type="submit"
                [class.is-loading]="isLoading"
                [disabled]="registrationForm.invalid">
          Register
        </button>
      </div>
    </form>
  </div>
</div>
<div class="page-separator justify-content-center m-0">
  <div class="page-separator__text">OR</div>
  <div class="page-separator__bg-top "></div>
</div>
<div class="bg-body pt-32pt pb-32pt pb-md-64pt text-center">
  <div class="container-fluid page__container">
    <a routerLink="/login"
       class="btn btn-secondary btn-block-xs">LOGIN</a>
  </div>
</div>
