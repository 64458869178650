import {PaymentGatewayEntity} from './payment-gateway-entity';
import {PortalService} from './portal-service';
import {PortalUser} from './portal-user';
import {GeneralUtil} from '../util/general-util';

export class WalletTransaction {
  public id: number;
  public transactionType: string;
  public amount: number;
  public transactionId: string;
  public transactionDescription: string;
  public convenienceCharge: number;
  public regDate: string;
  public executionDate: string;
  public paymentStatus: string;
  public pspPayload: string;
  public valueStatus: string;
  public paymentGateway: string;
  public portalUser = new PortalUser();

  public mapToEntity(data: any): void
  {
    if (GeneralUtil.isValidJSON(data)) {
      this.id = data.id;
      this.transactionType = data.transactionType;
      this.amount = data.amount;
      this.transactionId = data.transactionId;
      this.transactionDescription = data.transactionDescription;
      this.convenienceCharge = data.convenienceCharge;
      this.regDate = GeneralUtil.getDateTime(data.regDate.timestamp);
      this.executionDate = data.executionDate ?  GeneralUtil.getDateTime(data.executionDate.timestamp) : '';
      this.paymentStatus = data.paymentStatus;
      this.pspPayload = data.pspPayload;
      this.valueStatus = data.valueStatus;
      this.paymentGateway = data.paymentGateway;
      this.portalUser.mapToEntity(data.portalUser);
    }
  }
}
