import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {GeneralUtil} from '../../../util/general-util';
import {AirtimeNetwork} from '../../../constant/airtime-network.enum';
import {BuyAirtimeDto} from '../../../dto/buy-airtime-dto';
import {ProductService} from '../../../service/product.service';
import {HttpErrorResponse} from '@angular/common/http';
import {TransactionSummaryDto} from '../../../entity/transaction-summary-dto';
declare function showToast(msg): any;

@Component({
  selector: 'app-airtime',
  templateUrl: './airtime.component.html',
  styleUrls: ['./airtime.component.css']
})
export class AirtimeComponent implements OnInit {


  public selectedNetwork: any;
  public airtimeNetwork = AirtimeNetwork;
  public buyAirtimeDto = new BuyAirtimeDto();
  public isLoading = false;

  constructor(public route: ActivatedRoute,
              public router: Router,
              public productService: ProductService) {
  }

  ngOnInit(): void {
    // fetch network-selection
    this.route.paramMap.subscribe((params: ParamMap) => {
      const selectedNetwork = params.get('network-selection');

      if (GeneralUtil.isValidString(selectedNetwork)) {
        this.selectedNetwork = selectedNetwork;
      }

    });

  }


  buyAirtime(network: AirtimeNetwork): void {
    this.buyAirtimeDto.networkType = network;
    this.isLoading = true;

    this.productService.initiateAirtimePurchase(this.buyAirtimeDto)
      .subscribe(data => {
        const transactn = new TransactionSummaryDto();
        transactn.mapToEntity(data.data);
        localStorage.setItem('TRANSACTION_SUMMARY', JSON.stringify(transactn));

        this.isLoading = false;

        this.router.navigate(['/transaction-summary']);
        return;
      },
        error1 => {
          this.isLoading = false;
          this.errorHandler(error1);
      });

  }

  refreshDto(): void {
    this.buyAirtimeDto = new BuyAirtimeDto();
  }


  // Error handling
  public errorHandler(error) {

    if (error instanceof HttpErrorResponse) {

      if (error.error.message) {
        showToast(error.error.message);
        return;
      }

      showToast('Network error');
      return;
    }
    showToast('Network error');
    return;
  }


}
