<div class="container page__container">
  <div class="page-section">

    <div class="row mb-lg-12pt">



      <div class="col-lg-12" *ngIf="isLoading1">
        <div style="max-width: 100%;" >
          <div class="loader-list" align="center">
            <div class="loader loader-primary"></div>
          </div>
        </div>
      </div>



      <!-----------BUY ELECTRICITY------------>
      <div class="col-lg-12" *ngIf="!isLoading1">

        <div class="row align-items-center mb-8pt">

          <div class="col-md-6">
            <div class="card card--raised">
              <div class="card-header d-flex align-items-center">
                <div class="flex">
                  <h4 class="card-title">Electricity</h4>
                  <p class="card-subtitle">Pay for all electricity bills without a single hassle</p>
                </div>
                <div class="avatar avatar-sm mr-8pt">
                  <span class="avatar-title rounded bg-accent">{{buyElectricityDto.discoName | slice:0:2 | uppercase}} </span>
                </div>
              </div>
              <div class="card-body">
                <form #electricityForm="ngForm" novalidate (ngSubmit)="buyElectricity()">



                  <div class="form-group">
                    <label class="form-label" for="disco">DISCO</label>
                    <select required id="disco" name="disco" #disco="ngModel"
                            data-toggle="select" [(ngModel)]="buyElectricityDto.discoName"
                            class="form-control">
                      <option *ngFor="let servc of serviceDetailsList"
                              [value]="servc.serviceID">
                        {{servc.name}}
                      </option>
                    </select>
                  </div>

                  <div class="form-group">
                    <label class="form-label"
                           for="emailA">Meter number</label>

                    <div class="input-group input-group-merge">
                      <input type="tel" pattern="[0-9]*"
                             class="form-control form-control-appended" required="" id="meterNo"
                             placeholder="Enter your meter number" name="meterNo" #meterNo="ngModel"
                             [class.is-invalid]="meterNo.invalid && meterNo.touched"
                             [(ngModel)]="buyElectricityDto.meterNo">
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="material-icons">tungsten</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label class="form-label" for="meterType">Meter Type</label>
                    <select required id="meterType" name="meterType" #meterType="ngModel"
                            data-toggle="select" [(ngModel)]="buyElectricityDto.meterType"
                            class="form-control">
                      <option value="prepaid">PREPAID</option>
                      <option value="postpaid">POSTPAID</option>
                    </select>
                  </div>

                  <div class="form-group">
                    <label class="form-label" for="phoneA">Amount</label>

                    <div class="input-group input-group-merge">
                      <input type="number"
                             class="form-control form-control-appended is-invalid" id="amount"
                             placeholder="Enter your recharge amount" name="amount" #amount="ngModel" required
                             [class.is-invalid]="amount.invalid && amount.touched"
                             [(ngModel)]="buyElectricityDto.amount">
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="material-icons">label_important</span>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="form-group">
                    <label class="form-label" for="phoneA">Phone number</label>

                    <div class="input-group input-group-merge">
                      <input type="text"
                             class="form-control form-control-appended is-invalid" id="phoneA"
                             placeholder="Enter your phone number" name="phone" #phone="ngModel" required
                             [class.is-invalid]="phone.invalid && phone.touched"
                             [(ngModel)]="buyElectricityDto.phone">
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="material-icons">phone_android</span>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="form-group">
                    <label class="form-label"
                           for="emailA">Email address</label>

                    <div class="input-group input-group-merge">
                      <input type="email"
                             class="form-control form-control-appended" required="" id="emailA"
                             pattern="^[^@]+@[^@]+\.[^@]+$"
                             placeholder="Enter your email" name="email" #email="ngModel"
                             [class.is-invalid]="email.invalid && email.touched"
                             [(ngModel)]="buyElectricityDto.email">
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="material-icons">alternate_email</span>
                        </div>
                      </div>
                    </div>
                  </div>


                  <button class="btn btn-accent" type="submit"
                          [class.is-loading]="isLoading2"
                          [disabled]="electricityForm.form.invalid || isLoading2">
                    BUY
                  </button>
                </form>
              </div>
            </div>

          </div>

        </div>

      </div>

    </div>

  </div>
</div>
