import {GeneralUtil} from '../util/general-util';

export class AirtimeTransaction {
  public id: number;
  public network: string;
  public amount: number;
  public phoneNumber: string;
  public email: string;
  public description: string;
  public transactionId: string;
  public transactionTime: string;
  public convinienceCharge: number;

  constructor() {
  }

  public mapToEntity(data: any): void {
    if (GeneralUtil.isValidJSON(data)) {
      this.id = data.id;
      this.network = data.network;
      this.amount = data.amount;
      this.phoneNumber = data.phoneNumber;
      this.email = data.email;
      this.description = data.description;
      this.transactionId = data.transactionId;
      this.transactionTime = GeneralUtil.getDateTime(data.transactionTime.timestamp);
      this.convinienceCharge = data.convinienceCharge;
    }
  }

}
