import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {GeneralEnum} from '../constant/general-enum.enum';
import {catchError} from 'rxjs/operators';
import {PaymentGateway} from '../constant/payment-gateway.enum';

@Injectable({
  providedIn: 'root'
})
export class WalletService {

  constructor(public httpClient: HttpClient) { }

  public getWalletDetails(): Observable<any> {
    const url = GeneralEnum.baseUrl + '/wallet/details';

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }


  public getWalletTransactionsForPortalUser(search: string, offset: number, limit: number): Observable<any> {
    const url = GeneralEnum.baseUrl + '/wallet/portaluser-transactions?search=' + search
      + '&offset=' + offset + '&limit=' + limit;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }


  public initWalletCreditAction(amountt: number): Observable<any> {
    const url = GeneralEnum.baseUrl + '/wallet/init-credit-transaction';

    const dto = {
      amount: amountt
    };

    return this.httpClient.post<any>(url, dto)
      .pipe(catchError(this.errorHandler));
  }


  public completeWalletCreditTransaction(pg: PaymentGateway, transactionId: string): Observable<any> {
    const url = GeneralEnum.baseUrl + '/wallet/complete-credit-transaction/' + pg  +
      '/' + transactionId;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }


  // Error Handler
  errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }
}
