import {GeneralUtil} from '../util/general-util';

export class UserAccount {
  public id: number;
  public email: string;
  public phone: string;
  public accountStatus: string;
  public role: string;

  public mapToEntity(data: any): void
  {
    if (GeneralUtil.isValidJSON(data)) {
      this.id = data.id;
      this.email = data.email;
      this.phone = data.phone;
      this.accountStatus = data.accountStatus;
      this.role = data.userRole.name;
    }
  }
}
