/*import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})*/
export class PaginationService {
  private previousPreviousPreviousPage = 0;
  private previousPreviousPage = 0;
  private previousPage = 0;
  private nextPage = 0;
  private nextNextpage = 0;
  private nextNextNextPage = 0;


  private defaultLimit = 0;
  private currentPage = 0;

  private offset = 0;
  private limit = 0;

  constructor(defaultLimit, currentPage) {
    this.defaultLimit = defaultLimit;
    this.currentPage = currentPage;
  }

  /**
   * Get offset - To query data from the db
   */
  public getOffset(): number {
    if (this.currentPage === undefined || this.currentPage < 1 || this.currentPage == 1) {
      this.currentPage = 1;
      this.offset = 0;
      this.limit = this.defaultLimit;
      return this.offset;
    } else if (this.currentPage > 1) {
      this.offset = (this.currentPage - 1) * this.defaultLimit;
      this.limit =  this.defaultLimit;
      return this.offset;
    }
  }

  /**
   * Get Limit - To query data from the db
   */
  public getLimit() {
    return this.defaultLimit;
  }

  public getNextPage(totalCount: number ): number {

    if ((this.offset + this.defaultLimit) < totalCount) {
      this.nextPage = Number(this.currentPage) + 1;
    }
    return this.nextPage;
  }

  public getNextNextPage(totalCount: number) {
    if ((this.offset + this.defaultLimit * 2 ) < totalCount) {
      this.nextNextpage = Number(this.currentPage) + 2;
    }
    return this.nextNextpage;
  }

  public getNextNextNextPage(totalCount: number) {
    if ((this.offset + this.defaultLimit * 3) < totalCount) {
      this.nextNextNextPage = Number(this.currentPage) + 3;
    }
    return this.nextNextNextPage;
  }

  public getPreviousPage(): number {
    this.previousPage =  Number(this.currentPage) - 1;
    return this.previousPage;
  }

  public getPreviousPreviousPage(): number {
    this.previousPreviousPage =  Number(this.currentPage) - 2;
    return this.previousPreviousPage;
  }

  public getPreviousPreviousPreviousPage(): number {
    this.previousPreviousPreviousPage = Number(this.currentPage) - 3;
    return this.previousPreviousPreviousPage;
  }
}
