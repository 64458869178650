import {GeneralUtil} from '../util/general-util';

export class PaymentGatewayEntity {
  public id: number;
  public name: string;
  public publicKey: string;
  public paymentRequestUrl: string;
  public paymentResponseUrl: string;
  public postPaymentConfirmationUrl: string;
  public prePaymentRequestUrl: string;

  public mapToEntity(data: any): void
  {
    if (GeneralUtil.isValidJSON(data)) {
      this.id = data.id;
      this.name = data.name;
      this.publicKey = data.publicKey;
      this.paymentRequestUrl = data.paymentRequestUrl;
      this.paymentResponseUrl = data.paymentResponseUrl;
      this.postPaymentConfirmationUrl = data.postPaymentConfirmationUrl;
      this.prePaymentRequestUrl = data.prePaymentRequestUrl;
    }
  }
}
