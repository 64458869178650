import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {BuyAirtimeDto} from '../dto/buy-airtime-dto';
import {GeneralEnum} from '../constant/general-enum.enum';
import {catchError} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {ConfirmServicePurchaseDto} from '../dto/confirm-service-purchase-dto';
import {BuyDataDto} from '../dto/buy-data-dto';
import {BuyTvDto} from '../dto/buy-tv-dto';
import {BuyElectricityDto} from '../dto/buy-electricity-dto';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(public httpClient: HttpClient) { }

  public initiateAirtimePurchase(dto: BuyAirtimeDto): Observable<any> {
    const url = GeneralEnum.baseUrl + '/service-purchase-request/AIRTIME';

    return this.httpClient.post<any>(url, dto)
      .pipe(catchError(this.errorHandler));
  }

  public initializeDataPurchase(dto: BuyDataDto): Observable<any> {
    const url = GeneralEnum.baseUrl + '/service-purchase-request/DATA';

    return this.httpClient.post<any>(url, dto)
      .pipe(catchError(this.errorHandler));
  }

  public initializeTvPurchase(dto: BuyTvDto): Observable<any> {
    const url = GeneralEnum.baseUrl + '/service-purchase-request/TV-SUBSCRIPTION';

    return this.httpClient.post<any>(url, dto)
      .pipe(catchError(this.errorHandler));
  }

  public initializeElectricityPurchase(dto: BuyElectricityDto): Observable<any> {
    const url = GeneralEnum.baseUrl + '/service-purchase-request/ELECTRICITY-BILL';

    return this.httpClient.post<any>(url, dto)
      .pipe(catchError(this.errorHandler));
  }

  public getDataVariationsForAllNetworks(): Observable<any> {
    const url = GeneralEnum.baseUrl + '/fetch-variation-services/DATA';

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  public getTvVariationsForAllTvs(): Observable<any> {
    const url = GeneralEnum.baseUrl + '/fetch-variation-services/TV-SUBSCRIPTION';

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }


  public getAllElectricityServices(): Observable<any> {
    const url = GeneralEnum.baseUrl + '/electricity-services';

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }


  public confirmServicePurchase(dto: ConfirmServicePurchaseDto): Observable<any> {
    const url = GeneralEnum.baseUrl + '/post-service-purchase-request';

    return this.httpClient.post<any>(url, dto)
      .pipe(catchError(this.errorHandler));
  }


  // Error Handler
  errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }
}
