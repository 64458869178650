import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../../service/auth/auth.service';
import {Router} from '@angular/router';
import {RoleConstant} from '../../../constant/role-constant.enum';
declare function showToast(msg): any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public RoleConstant = RoleConstant;
  constructor(public authService: AuthService,
              public router: Router) { }

  ngOnInit(): void {
  }

  logoutAction(): void {
    this.authService.resetUserDetails();
    this.router.navigate(['/']);
    showToast('Logout successful');
  }

}
