import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../../../service/auth/auth.service';
import {Router} from '@angular/router';
import {WalletService} from '../../../service/wallet.service';
import {HttpErrorResponse} from '@angular/common/http';
import {Wallet} from '../../../entity/wallet';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {InitWalletCredit} from '../../../dto/init-wallet-credit';
import {GeneralUtil} from '../../../util/general-util';
import {PaginationDataDto} from '../../../dto/pagination-data-dto';
import {WalletTransaction} from '../../../entity/wallet-transaction';
import {GeneralEnum} from '../../../constant/general-enum.enum';
import {PaginationService} from '../../../service/pagination.service';
import {ServiceTransaction} from '../../../entity/service-transaction';
declare function showToast(msg): any;

@Component({
  selector: 'app-user-wallet',
  templateUrl: './user-wallet.component.html',
  styleUrls: ['./user-wallet.component.css']
})
export class UserWalletComponent implements OnInit {
  public isLoading = false;
  public walletFundLoading = false;

  public walletDto = new Wallet();

  public closeResult = '';
  public fundWalletAmount = 0;

  public paginationData = new PaginationDataDto();
  public walletTransactions: WalletTransaction[] = [];


  constructor(public authService: AuthService,
              public walletService: WalletService,
              private modalService: NgbModal,
              public cdf: ChangeDetectorRef,
              public router: Router) { }

  ngOnInit(): void {
    this.initializePaginationData();
    this.getWalletDetails();

    setTimeout(() => {
      this.isLoading = false;
      this.getWalletTransactions();
    }, 500);
  }

  getWalletDetails(): void {
    this.walletService.getWalletDetails()
      .subscribe(data => {
        this.walletDto.mapToEntity(data.data);
      }, error1 => this.errorHandler(error1));
  }

  getWalletTransactions(): void
  {
    this.isLoading = true;

    const defaultLimit = this.paginationData.defaultLimit;
    const currentPage = this.paginationData.page;

    // initialize pagination service
    const paginationServc = new PaginationService(defaultLimit, currentPage);

    // get offset and limit
    const offset = paginationServc.getOffset();
    const limit = paginationServc.getLimit();

    this.walletService.getWalletTransactionsForPortalUser('', offset, limit)
      .subscribe(data => {
        this.walletTransactions = [];
        this.paginationData.totalSize = data.data.totalSize;

        if (data.data.entities.length > 0) {

          data.data.entities.forEach(tr => {
            const transactn = new WalletTransaction();
            transactn.mapToEntity(tr);
            this.walletTransactions.push(transactn);
          });
        }

        this.isLoading = false;


      }, error1 => {
        this.isLoading = false;
        this.errorHandler(error1);
      });
  }


  openModal(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  fundWalletAction(modal: any): void {
    if (this.fundWalletAmount < 100) {
      showToast('A minimum of N100 is required to fund your wallet');
      return;
    }

    this.walletFundLoading = true;

    this.walletService.initWalletCreditAction(this.fundWalletAmount)
      .subscribe(data => {
          const initDto = new InitWalletCredit();
          initDto.mapToEntity(data.data);
          sessionStorage.setItem('INIT_WALLET_CREDIT_DTO', JSON.stringify(initDto));
          this.walletFundLoading = false;
          modal.dismiss('');


          this.router.navigate(['/user-dashboard/fund-wallet']);
      }, error1 => {
        this.walletFundLoading = false;
        modal.dismiss('');
        this.errorHandler(error1);
      });

  }

  formatAmount(amount: number): string {
    return GeneralUtil.formatWithCommas(amount);
  }


  initializePaginationData(): void {
    this.paginationData.page = 1;
    this.paginationData.defaultLimit = GeneralEnum.paginationLimit;
    this.paginationData.totalSize = 100;
  }


  // Error handling
  public errorHandler(error): void {

    if (error instanceof HttpErrorResponse) {

      if (error.error.message) {
        showToast(error.error.message);
        return;
      }

      showToast('Network error');
      return;
    }
    showToast('Network error');
    return;
  }
}
