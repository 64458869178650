<!---------------TRANSACTIONS------------------->
<div class="container page__container">
  <div class="page-section">

    <div class="col-lg-12" *ngIf="isLoading">
      <div style="max-width: 100%;" >
        <div class="loader-list" align="center">
          <div class="loader loader-primary"></div>
        </div>
      </div>
    </div>


    <div class="page-separator">
      <div class="page-separator__text">All Transactions</div>
    </div>

    <div class="card mb-lg-32pt">

      <div class="table-responsive"
           data-toggle="lists"
           data-lists-sort-by="js-lists-values-employee-name"
           data-lists-values='["js-lists-values-employee-name", "js-lists-values-employer-name",
           "js-lists-values-projects", "js-lists-values-activity", "js-lists-values-earnings"]'>

        <div class="card-header">
          <div class="flex" style="max-width: 100%">

            <form #filterForm="ngForm"  novalidate  >

              <div class="form-group">
                <div class="row">


                  <div class="col-lg">
                    <label class="form-label" for="searchSample03">Search</label>

                    <div class="search-form search-form--dark">
                      <input type="text" class="form-control" name="search" #search="ngModel"
                             [(ngModel)]="filterTransactionDto.search" placeholder="Search icons"
                             id="searchSample03">

                      <button class="btn" type="button" (click)="filterTransactions()">
                        <i class="material-icons">search</i>
                      </button>
                    </div>
                  </div>


                  <div class="col-lg">
                    <label class="form-label"
                           for="custom-select">Value Status</label>
                    <select id="custom-select2" class="form-control custom-select" (change)="filterTransactions()"
                      name="valueS" #valueS="ngModel" [(ngModel)]="filterTransactionDto.valueStatus">
                      <option selected value="">ALL</option>
                      <option value="HAS_VALUE">HAS VALUE</option>
                      <option value="NO_VALUE">NO VALUE</option>
                      <option value="UNCONFIRMED">UNCONFIRMED</option>
                    </select>
                  </div>



                  <div class="col-lg">
                    <label class="form-label"
                           for="custom-select">Payment status</label>
                    <select id="custom-select3" class="form-control custom-select"
                       name="paymentStatus" #paymentStatus="ngModel" (change)="filterTransactions()"
                            [(ngModel)]="filterTransactionDto.paymentStatus">
                      <option selected value="">ALL</option>
                      <option value="PAID">PAID</option>
                      <option value="NOT_PAID">NOT PAID</option>
                      <option value="CANCELLED">CANCELLED</option>
                    </select>
                  </div>


                  <div class="col-lg">
                    <label class="form-label"
                           for="custom-select">Service</label>
                    <select id="custom-select"
                            class="form-control custom-select">
                      <option selected>Open this select menu</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>

                  <div>
                    <div>
                      <button class="btn" type="button" (click)="clearFilter()">
                        <i class="material-icons">clear</i>
                      </button>
                    </div>
                  </div>

                </div>
              </div>
            </form>

          </div>

          <!--<div class="flex" style="max-width:100%">
            <button class="btn" type="button" style="float: right">
              <i class="material-icons">search</i>
            </button>
          </div>-->

        </div>

        <table class="table mb-0 thead-border-top-0 table-nowrap">

          <thead>
          <tr>

            <th>
              <a data-sort="js-lists-values-employee-name">Transaction ID</a>
            </th>

            <th style="width: 120px;">
              <a data-sort="js-lists-values-activity">Service Name</a>
            </th>

            <th style="width: 150px;">
              <a data-sort="js-lists-values-employer-name">Total Fee</a>
            </th>

            <th class="text-center"
                style="width: 48px;">
              <a data-sort="js-lists-values-projects">Date</a>
            </th>

            <th style="width: 37px;">Payment Status</th>

            <th style="width: 51px;">
              <a data-sort="js-lists-values-earnings">Value Status</a>
            </th>

            <th style="width: 51px;">
              <a data-sort="js-lists-values-earnings">Action</a>
            </th>

            <th style="width: 24px;"
                class="pl-0"></th>
          </tr>
          </thead>



          <tbody class="list" id="staff">
          <tr class="selected" *ngFor="let transactn of transactions">
            <td>
              <div class="d-flex align-items-center">
                <a class="text-success" *ngIf="transactn.valueStatus == 'HAS VALUE'">
                  <i class="material-icons mr-8pt">thumb_up_alt</i>
                </a>
                <a class="text-warning" *ngIf="transactn.valueStatus !== 'HAS VALUE'">
                  <i class="material-icons mr-8pt">thumb_down_alt</i>
                </a>

                <a href=""
                   class="text-70"><span class="js-lists-values-employer-name">{{transactn.transactionId}}</span></a>
              </div>
            </td>


            <td class="text-center js-lists-values-projects small">{{transactn.portalService.name}}</td>

            <td class="js-lists-values-earnings small">{{'N'+ transactn.totalFee}}</td>


            <td class="text-50 js-lists-values-activity small">{{transactn.regDate}}</td>

            <td><a class="chip chip-outline-secondary">{{transactn.paymentStatus}}</a></td>


            <td class="js-lists-values-earnings small">{{transactn.valueStatus}}</td>

            <td class="js-lists-values-earnings small">
              <div class="btn-group">
                <button type="button"
                        class="btn btn-sm btn-outline-secondary dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false">Action</button>
                <div class="dropdown-menu">
                  <a class="dropdown-item"
                     href="#">View</a>
                  <a class="dropdown-item"
                     href="#">Requery</a>
                </div>
              </div>
            </td>


          </tr>
          </tbody>

        </table>
      </div>

      <div class="card-footer p-8pt">
        <ul class="pagination justify-content-start pagination-xsm m-0">
          <ngb-pagination [collectionSize]="paginationData.totalSize" [(page)]="paginationData.page"
                          (pageChange)="getTransactions()"
                          [pageSize]="paginationData.defaultLimit" [maxSize]="5" [rotate]="true" [boundaryLinks]="false">

          </ngb-pagination>
        </ul>
      </div>

    </div>

  </div>
</div>
