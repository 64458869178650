import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './component/home/home.component';
import {AirtimeComponent} from './component/services/airtime/airtime.component';
import {TransactionSummaryComponent} from './component/transaction-summary/transaction-summary.component';
import {DataComponent} from './component/services/data/data.component';
import {TvComponent} from './component/services/tv/tv.component';
import {ElectricityComponent} from './component/services/electricity/electricity.component';
import {LoginComponent} from './component/login/login.component';
import {UDashboardComponent} from './component/userDashboard/u-dashboard/u-dashboard.component';
import {AuthGuard} from './service/auth/auth.guard';
import {UserTransactionsComponent} from './component/userDashboard/user-transactions/user-transactions.component';
import {UserWalletComponent} from './component/userDashboard/user-wallet/user-wallet.component';
import {FundWalletComponent} from './component/userDashboard/user-wallet/fund-wallet/fund-wallet.component';
import {ADashboardComponent} from './component/adminDashboard/a-dashboard/a-dashboard.component';
import {ATransactionsComponent} from './component/adminDashboard/a-transactions/a-transactions.component';
import {UserRegistrationComponent} from './component/user-registration/user-registration.component';
import {EmailVerificationComponent} from './component/email-verification/email-verification.component';
import {ResendEmailVerificationTokenComponent} from './component/resend-email-verification-token/resend-email-verification-token.component';

const routes: Routes = [
  // {path: '', redirectTo: '/home', pathMatch: 'full'},
  // {path: 'home', pathMatch: 'full', component: HomeComponent},
  {path: '', pathMatch: 'full', component: HomeComponent},
  {path: 'services/airtime/:network-selection', pathMatch: 'full', component: AirtimeComponent},
  {path: 'services/data/:network-selection', pathMatch: 'full', component: DataComponent},
  {path: 'services/tv/:tv-type', pathMatch: 'full', component: TvComponent},
  {path: 'services/electricity', pathMatch: 'full', component: ElectricityComponent},
  {path: 'transaction-summary', pathMatch: 'full', component: TransactionSummaryComponent},
  {path: 'login', pathMatch: 'full', component: LoginComponent},
  {path: 'user-registration', pathMatch: 'full', component: UserRegistrationComponent},
  {path: 'validate-email/:validation-token', pathMatch: 'full', component: EmailVerificationComponent},
  {path: 'resend-email-validation-token', pathMatch: 'full', component: ResendEmailVerificationTokenComponent},

  // --------user dashboard---------
  {path: 'user-dashboard', pathMatch: 'full', component: UDashboardComponent, canActivate: [AuthGuard]},
  {path: 'user-dashboard/transactions', pathMatch: 'full', component: UserTransactionsComponent, canActivate: [AuthGuard]},
  {path: 'user-dashboard/wallet', pathMatch: 'full', component: UserWalletComponent, canActivate: [AuthGuard]},
  {path: 'user-dashboard/fund-wallet', pathMatch: 'full', component: FundWalletComponent, canActivate: [AuthGuard]},

  // --------admin dashboard---------
  {path: 'admin-dashboard', pathMatch: 'full', component: ADashboardComponent, canActivate: [AuthGuard]},
  {path: 'admin-dashboard/transactions', pathMatch: 'full', component: ATransactionsComponent, canActivate: [AuthGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
