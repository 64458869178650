import { Component, OnInit } from '@angular/core';
import {RegisterUserDto} from '../../dto/register-user-dto';
import {Router} from '@angular/router';
import {AuthService} from '../../service/auth/auth.service';
import {HttpErrorResponse} from '@angular/common/http';
declare function showToast(msg): any;

@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.css']
})
export class UserRegistrationComponent implements OnInit {

  // variables
  public registerUserDto = new RegisterUserDto();
  public isLoading = false;
  public notification: string = null;

  constructor(public router: Router,
              public authService: AuthService) { }

  ngOnInit(): void {
    this.redirectIfLoggedIn();
  }


  redirectIfLoggedIn(): void {
    if (this.authService.isLoggedIn()) {
      this.router.navigate(['/login']);
    }
  }

  registerUserAction(loginForm): void {
    this.isLoading = true;

    this.authService.registerUser(this.registerUserDto)
      .subscribe(data => {
        this.notification = data.data.message;
        showToast(data.data.message);
        loginForm.resetForm();
        this.isLoading = false;
      },

        error => {
          this.isLoading = false;
          this.errorHandler(error);
      });
  }


  // Error handling
  public errorHandler(error): void {

    if (error instanceof HttpErrorResponse) {

      if (error.error.message) {
        showToast(error.error.message);
        return;
      }

      showToast('Network error');
      return;
    }
    showToast('Network error');
    return;
  }

}
