import {GeneralUtil} from '../util/general-util';

export class Wallet {
  public id: number;
  public amount = 0.0;

  public mapToEntity(data: any): void {
    if (GeneralUtil.isValidJSON(data)) {
      this.id = data.id;
      this.amount = data.amount;
    }
  }
}
