export class BuyElectricityDto {
  public discoName: string;
  public meterNo: string;
  public meterType: string;
  public amount: number;
  public phone: string;
  public email: string;


}
