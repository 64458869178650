import { Component, OnInit } from '@angular/core';
import {LoginDto} from '../../dto/login-dto';
import {AuthService} from '../../service/auth/auth.service';
import {HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {RoleConstant} from '../../constant/role-constant.enum';
declare function showToast(msg): any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public loginDto = new LoginDto();
  public isLoading = false;
  constructor(public authService: AuthService,
              public router: Router) { }

  ngOnInit(): void {
    this.redirectIfLoggedIn();
  }

  loginAction(): void {
    this.isLoading = true;

    this.authService.loginUser(this.loginDto)
      .subscribe(data => {
          this.isLoading = false;
          this.authService.resetUserDetails();
          this.authService.setUserDetails(data.data);
          showToast('Login successful');
          this.redirectAfterLogin();


      },
        error => {
        this.isLoading = false;
        this.errorHandler(error);
      });
  }


  redirectAfterLogin(): void {
    if (this.authService.getUserDetailItem(AuthService.userDetailsRole) == RoleConstant.PORTAL_USER) {
      this.router.navigate(['/user-dashboard']);
      return;
    }

    if (this.authService.getUserDetailItem(AuthService.userDetailsRole) == RoleConstant.ADMINISTRATOR) {
      this.router.navigate(['/admin-dashboard']);
      return;
    }
  }

  redirectIfLoggedIn(): void {
    if (this.authService.isLoggedIn()) {
      this.redirectAfterLogin();
    }
  }

  // Error handling
  public errorHandler(error): void {

    console.log('error:::::', error);

    if (error instanceof HttpErrorResponse) {

      if (error.error.status === 428) {
        this.router.navigate(['/resend-email-validation-token']);
      }

      if (error.error.message) {
        showToast(error.error.message);
        return;
      }

      showToast('Network error');
      return;
    }
    showToast('Network error');
    return;
  }

}
