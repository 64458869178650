import { Component, OnInit } from '@angular/core';
import {DataNetworkEnum} from '../../../constant/data-network-enum.enum';
import {BuyDataDto} from '../../../dto/buy-data-dto';
import {TvTypeEnum} from '../../../constant/tv-type-enum.enum';
import {BuyTvDto} from '../../../dto/buy-tv-dto';
import {VariationsDto} from '../../../dto/variations-dto';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {ProductService} from '../../../service/product.service';
import {GeneralUtil} from '../../../util/general-util';
import {ServiceWithVariationsDto} from '../../../dto/service-with-variations-dto';
import {HttpErrorResponse} from '@angular/common/http';
import {TransactionSummaryDto} from '../../../entity/transaction-summary-dto';
declare function showToast(msg): any;

@Component({
  selector: 'app-tv',
  templateUrl: './tv.component.html',
  styleUrls: ['./tv.component.css']
})
export class TvComponent implements OnInit {

  public selectedTvType: any;
  public tvType = TvTypeEnum;
  public buyTvDto = new BuyTvDto();

  public dstvVariations: VariationsDto[] = [];
  public gotvVariations: VariationsDto[] = [];
  public starTimesVariations: VariationsDto[] = [];

  public isLoading1 = true;
  public isLoading2 = false;

  constructor(public route: ActivatedRoute,
              public router: Router,
              public productService: ProductService) {
  }

  ngOnInit(): void {
    // fetch network-selection
    this.route.paramMap.subscribe((params: ParamMap) => {
      const selectedTv = params.get('tv-type');

      if (GeneralUtil.isValidString(selectedTv)) {
        this.selectedTvType = selectedTv;
      }
    });

    this.getTvVariations();
  }

  getTvVariations(): void {

    this.productService.getTvVariationsForAllTvs()
      .subscribe(data => {
          if (data.data.length > 0) {

            // get all service-with-variations
            const services: ServiceWithVariationsDto[] = [];

            data.data.forEach(s => {
              const service = new ServiceWithVariationsDto();
              service.mapToDto(s);
              services.push(service);
            });

            // get all variatons by tvType (dstv, gotv,  startimes)
            // 1. dstv
            const dstvService = services.filter(s => {
              return s.serviceID === TvTypeEnum.DSTV;
            })[0];

            if (GeneralUtil.isValidJSON(dstvService)) {
              this.dstvVariations = dstvService.variations;
            }

            // 2. gotv
            const gotvService = services.filter(s => {
              return s.serviceID === TvTypeEnum.GOTV;
            })[0];


            if (GeneralUtil.isValidJSON(gotvService)) {
              this.gotvVariations = gotvService.variations;
            }


            // 3. startimes
            const startimesService = services.filter(s => {
              return s.serviceID === TvTypeEnum.STARTIMES;
            })[0];


            if (GeneralUtil.isValidJSON(startimesService)) {
              this.starTimesVariations = startimesService.variations;
            }

            this.isLoading1 = false;
          }
        },
        error1 => {
          this.isLoading1 = true;
          this.errorHandler(error1);
        });
  }


  buyTv(tvType: TvTypeEnum): void {

    this.buyTvDto.tvType = tvType;
    this.isLoading2 = true;

    this.productService.initializeTvPurchase(this.buyTvDto)
      .subscribe(data => {
          const transactn = new TransactionSummaryDto();
          transactn.mapToEntity(data.data);
          localStorage.setItem('TRANSACTION_SUMMARY', JSON.stringify(transactn));

          this.isLoading2 = false;

          this.router.navigate(['/transaction-summary']);
          return;
        },
        error1 => {
          this.isLoading2 = false;
          this.errorHandler(error1);
        });

  }

  refreshDto(): void {
    this.buyTvDto = new BuyTvDto();
  }




  // Error handling
  public errorHandler(error) {

    if (error instanceof HttpErrorResponse) {

      if (error.error.message) {
        showToast(error.error.message);
        return;
      }

      showToast('Network error');
      return;
    }
    showToast('Network error');
    return;
  }

}
