<div class="container page__container">
  <div class="page-section">

    <div class="row mb-lg-12pt">


      <!-------OVERVIEW OF NETWORKS-------->
      <div class="col-lg-12">

        <div class="page-separator">
          <div class="page-separator__text">Please confirm your transaction details below</div>
        </div>

        <div class="row">

          <!--airtime transaction summary-->
          <div class="col-lg-9 pr-lg-0" *ngIf="transactnSummaryDto.serviceName == PortalService.AIRTIME">
            <div class="page-section">
              <div class="list-group list-group-form">

                <div class="list-group-item">
                  <div class="flex" style="font-size: x-large;">Transaction summary</div><br>

                  <div class="alert alert-accent" role="alert" *ngIf="notification != null">
                    <div class="d-flex flex-wrap align-items-start">
                      <div class="flex"
                           style="min-width: 180px">
                        <small class="text-black-100">
                          {{notification}}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="list-group-item">
                  <fieldset role="group"
                            aria-labelledby="label-type"
                            class="m-0 form-group">
                    <div class="form-row align-items-center">
                      <label
                             id="label-type"
                             class="col-md-3 col-form-label form-label">Service</label>
                      <div role="group"
                           aria-labelledby="label-type"
                           class="col-md-9">
                        <div role="group" class="btn-group btn-group-toggle">
                          <strong>Airtime</strong>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div class="list-group-item">
                  <fieldset role="group"
                            aria-labelledby="label-type"
                            class="m-0 form-group">
                    <div class="form-row align-items-center">
                      <label
                        id="label-type"
                        class="col-md-3 col-form-label form-label">Network</label>
                      <div role="group"
                           aria-labelledby="label-type"
                           class="col-md-9">
                        <div role="group" class="btn-group btn-group-toggle">
                          <strong>{{transactnSummaryDto.airtimeTransaction.network | uppercase}}</strong>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>


                <div class="list-group-item">
                  <fieldset role="group"
                            aria-labelledby="label-type"
                            class="m-0 form-group">
                    <div class="form-row align-items-center">
                      <label
                        id="label-type1"
                        class="col-md-3 col-form-label form-label">Phone number</label>
                      <div role="group"
                           aria-labelledby="label-type1"
                           class="col-md-9">
                        <div role="group" class="btn-group btn-group-toggle">
                          <strong>{{transactnSummaryDto.airtimeTransaction.phoneNumber}}</strong>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>


                <div class="list-group-item">
                  <fieldset role="group"
                            aria-labelledby="label-type"
                            class="m-0 form-group">
                    <div class="form-row align-items-center">
                      <label
                        id="label-type2"
                        class="col-md-3 col-form-label form-label">Amount</label>
                      <div role="group"
                           aria-labelledby="label-type2"
                           class="col-md-9">
                        <div role="group" class="btn-group btn-group-toggle">
                          <strong>
                            <span class="badge badge-primary" style="font-size: large">
                              N{{transactnSummaryDto.airtimeTransaction.amount}} +
                            N{{transactnSummaryDto.airtimeTransaction.convinienceCharge}} (convenience charge)
                            </span>

                          </strong>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div class="list-group-item">
                  <fieldset role="group"
                            aria-labelledby="label-type"
                            class="m-0 form-group">
                    <div class="form-row align-items-center">
                      <label
                        id="label-type4"
                        class="col-md-3 col-form-label form-label">Transaction id</label>
                      <div role="group"
                           aria-labelledby="label-type4"
                           class="col-md-9">
                        <div role="group" class="btn-group btn-group-toggle">
                          <strong>
                            {{transactnSummaryDto.airtimeTransaction.transactionId}}
                          </strong>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>


                <div class="list-group-item">
                  <fieldset role="group"
                            aria-labelledby="label-type"
                            class="m-0 form-group">
                    <div class="form-row align-items-center">
                      <label
                        id="label-type5"
                        class="col-md-3 col-form-label form-label">Transaction time</label>
                      <div role="group"
                           aria-labelledby="label-type5"
                           class="col-md-9">
                        <div role="group" class="btn-group btn-group-toggle">
                          <strong>
                            {{transactnSummaryDto.airtimeTransaction.transactionTime}}
                          </strong>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div class="list-group-item">
                  <div class="form-group row align-items-center mb-0">
                    <div class="col-sm-4">
                      <button (click)="payWithPaymentGateway(PaymentGateway.RAVEPAY)"
                              class="btn btn-accent" [class.is-loading]="isLoading"
                              [disabled]="isLoading">
                        <img src="assets/images/custom/flutter-pay.PNG" height="70px" width="240px">
                      </button>
                    </div><br>
                  </div>
                </div>


                <div class="list-group-item" *ngIf="authService.getUserDetailItem('USER-DETAILS-ROLE') == RoleConstant.PORTAL_USER">
                  <div class="form-group row align-items-center mb-0">
                    <div class="col-sm-4">
                      <button  class="btn btn-accent"
                               [disabled]="isLoading2" (click)="payWithWalletInit()">
                        Pay with WALLET</button>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <!--internet data transaction summary-->
          <div class="col-lg-9 pr-lg-0" *ngIf="transactnSummaryDto.serviceName == PortalService.DATA">
            <div class="page-section">
              <div class="list-group list-group-form">
                <div class="list-group-item">
                  <div class="flex" style="font-size: x-large;">Transaction summary</div><br>

                  <div class="alert alert-accent" role="alert" *ngIf="notification != null">
                    <div class="d-flex flex-wrap align-items-start">
                      <div class="flex"
                           style="min-width: 180px">
                        <small class="text-black-100">
                          {{notification}}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="list-group-item">
                  <fieldset role="group"
                            aria-labelledby="label-type"
                            class="m-0 form-group">
                    <div class="form-row align-items-center">
                      <label
                        id="label-type"
                        class="col-md-3 col-form-label form-label">Service</label>
                      <div role="group"
                           aria-labelledby="label-type"
                           class="col-md-9">
                        <div role="group" class="btn-group btn-group-toggle">
                          <strong>Internet Data</strong>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div class="list-group-item">
                  <fieldset role="group"
                            aria-labelledby="label-type"
                            class="m-0 form-group">
                    <div class="form-row align-items-center">
                      <label
                        id="label-type"
                        class="col-md-3 col-form-label form-label">Network</label>
                      <div role="group"
                           aria-labelledby="label-type"
                           class="col-md-9">
                        <div role="group" class="btn-group btn-group-toggle">
                          <strong>{{transactnSummaryDto.internetTransaction.network | uppercase}}</strong>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>


                <div class="list-group-item">
                  <fieldset role="group"
                            aria-labelledby="label-type"
                            class="m-0 form-group">
                    <div class="form-row align-items-center">
                      <label
                        id="label-type1"
                        class="col-md-3 col-form-label form-label">Phone number</label>
                      <div role="group"
                           aria-labelledby="label-type1"
                           class="col-md-9">
                        <div role="group" class="btn-group btn-group-toggle">
                          <strong>{{transactnSummaryDto.internetTransaction.phoneNumber}}</strong>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div class="list-group-item">
                  <fieldset role="group"
                            aria-labelledby="label-type"
                            class="m-0 form-group">
                    <div class="form-row align-items-center">
                      <label
                        id="label-type1"
                        class="col-md-3 col-form-label form-label">Bouquet details</label>
                      <div role="group"
                           aria-labelledby="label-type1"
                           class="col-md-9">
                        <div role="group" class="btn-group btn-group-toggle">
                          <strong>{{transactnSummaryDto.internetTransaction.bouqetDetails}}</strong>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>


                <div class="list-group-item">
                  <fieldset role="group"
                            aria-labelledby="label-type"
                            class="m-0 form-group">
                    <div class="form-row align-items-center">
                      <label
                        id="label-type2"
                        class="col-md-3 col-form-label form-label">Amount</label>
                      <div role="group"
                           aria-labelledby="label-type2"
                           class="col-md-9">
                        <div role="group" class="btn-group btn-group-toggle">
                          <strong>
                            <span class="badge badge-primary" style="font-size: large">
                              N{{transactnSummaryDto.internetTransaction.amount}} +
                            N{{transactnSummaryDto.internetTransaction.convinienceCharge}} (convenience charge)
                            </span>

                          </strong>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div class="list-group-item">
                  <fieldset role="group"
                            aria-labelledby="label-type"
                            class="m-0 form-group">
                    <div class="form-row align-items-center">
                      <label
                        id="label-type4"
                        class="col-md-3 col-form-label form-label">Transaction id</label>
                      <div role="group"
                           aria-labelledby="label-type4"
                           class="col-md-9">
                        <div role="group" class="btn-group btn-group-toggle">
                          <strong>
                            {{transactnSummaryDto.internetTransaction.transactionId}}
                          </strong>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>


                <div class="list-group-item">
                  <fieldset role="group"
                            aria-labelledby="label-type"
                            class="m-0 form-group">
                    <div class="form-row align-items-center">
                      <label
                        id="label-type5"
                        class="col-md-3 col-form-label form-label">Transaction time</label>
                      <div role="group"
                           aria-labelledby="label-type5"
                           class="col-md-9">
                        <div role="group" class="btn-group btn-group-toggle">
                          <strong>
                            {{transactnSummaryDto.internetTransaction.transactionTime}}
                          </strong>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div class="list-group-item">
                  <div class="form-group row align-items-center mb-0">
                    <div class="col-sm-4">
                      <button (click)="payWithPaymentGateway(PaymentGateway.RAVEPAY)"
                              class="btn btn-accent" [class.is-loading]="isLoading"
                              [disabled]="isLoading">
                        <img src="assets/images/custom/flutter-pay.PNG" height="70px" width="240px">
                      </button>
                    </div><br>
                  </div>
                </div>


                <div class="list-group-item" *ngIf="authService.isLoggedIn()">
                  <div class="form-group row align-items-center mb-0">
                    <div class="col-sm-4">
                      <button  class="btn btn-accent"
                               [disabled]="isLoading2" (click)="payWithWalletInit()">
                        Pay with WALLET</button>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <!--tv transaction summary-->
          <div class="col-lg-9 pr-lg-0" *ngIf="transactnSummaryDto.serviceName == PortalService.TV_SUBSCRIPTION">
            <div class="page-section">
              <div class="list-group list-group-form">
                <div class="list-group-item">
                  <div class="flex" style="font-size: x-large;">Transaction summary</div><br>

                  <div class="alert alert-accent" role="alert" *ngIf="notification != null">
                    <div class="d-flex flex-wrap align-items-start">
                      <div class="flex"
                           style="min-width: 180px">
                        <small class="text-black-100">
                          {{notification}}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="list-group-item">
                  <fieldset role="group"
                            aria-labelledby="label-type"
                            class="m-0 form-group">
                    <div class="form-row align-items-center">
                      <label
                        id="label-type"
                        class="col-md-3 col-form-label form-label">Service</label>
                      <div role="group"
                           aria-labelledby="label-type"
                           class="col-md-9">
                        <div role="group" class="btn-group btn-group-toggle">
                          <strong>TV Subscription</strong>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div class="list-group-item">
                  <fieldset role="group"
                            aria-labelledby="label-type"
                            class="m-0 form-group">
                    <div class="form-row align-items-center">
                      <label
                        id="label-type"
                        class="col-md-3 col-form-label form-label">TV Type</label>
                      <div role="group"
                           aria-labelledby="label-type"
                           class="col-md-9">
                        <div role="group" class="btn-group btn-group-toggle">
                          <strong>{{transactnSummaryDto.tvTransaction.tvType | uppercase}}</strong>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>


                <div class="list-group-item">
                  <fieldset role="group"
                            aria-labelledby="label-type"
                            class="m-0 form-group">
                    <div class="form-row align-items-center">
                      <label
                        id="label-type1"
                        class="col-md-3 col-form-label form-label">Decoder Number</label>
                      <div role="group"
                           aria-labelledby="label-type1"
                           class="col-md-9">
                        <div role="group" class="btn-group btn-group-toggle">
                          <strong>{{transactnSummaryDto.tvTransaction.decoderNo}}</strong>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div class="list-group-item">
                  <fieldset role="group"
                            aria-labelledby="label-type"
                            class="m-0 form-group">
                    <div class="form-row align-items-center">
                      <label
                        id="label-type1"
                        class="col-md-3 col-form-label form-label">Phone number</label>
                      <div role="group"
                           aria-labelledby="label-type1"
                           class="col-md-9">
                        <div role="group" class="btn-group btn-group-toggle">
                          <strong>{{transactnSummaryDto.tvTransaction.phoneNumber}}</strong>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div class="list-group-item">
                  <fieldset role="group"
                            aria-labelledby="label-type"
                            class="m-0 form-group">
                    <div class="form-row align-items-center">
                      <label
                        id="label-type1"
                        class="col-md-3 col-form-label form-label">Bouquet details</label>
                      <div role="group"
                           aria-labelledby="label-type1"
                           class="col-md-9">
                        <div role="group" class="btn-group btn-group-toggle">
                          <strong>{{transactnSummaryDto.tvTransaction.bouqetDetails}}</strong>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>


                <div class="list-group-item">
                  <fieldset role="group"
                            aria-labelledby="label-type"
                            class="m-0 form-group">
                    <div class="form-row align-items-center">
                      <label
                        id="label-type2"
                        class="col-md-3 col-form-label form-label">Amount</label>
                      <div role="group"
                           aria-labelledby="label-type2"
                           class="col-md-9">
                        <div role="group" class="btn-group btn-group-toggle">
                          <strong>
                            <span class="badge badge-primary" style="font-size: large">
                              N{{transactnSummaryDto.tvTransaction.amount}} +
                            N{{transactnSummaryDto.tvTransaction.convinienceCharge}} (convenience charge)
                            </span>

                          </strong>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div class="list-group-item">
                  <fieldset role="group"
                            aria-labelledby="label-type"
                            class="m-0 form-group">
                    <div class="form-row align-items-center">
                      <label
                        id="label-type4"
                        class="col-md-3 col-form-label form-label">Transaction id</label>
                      <div role="group"
                           aria-labelledby="label-type4"
                           class="col-md-9">
                        <div role="group" class="btn-group btn-group-toggle">
                          <strong>
                            {{transactnSummaryDto.tvTransaction.transactionId}}
                          </strong>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>


                <div class="list-group-item">
                  <fieldset role="group"
                            aria-labelledby="label-type"
                            class="m-0 form-group">
                    <div class="form-row align-items-center">
                      <label
                        id="label-type5"
                        class="col-md-3 col-form-label form-label">Transaction time</label>
                      <div role="group"
                           aria-labelledby="label-type5"
                           class="col-md-9">
                        <div role="group" class="btn-group btn-group-toggle">
                          <strong>
                            {{transactnSummaryDto.tvTransaction.transactionTime}}
                          </strong>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div class="list-group-item">
                  <div class="form-group row align-items-center mb-0">
                    <div class="col-sm-4">
                      <button (click)="payWithPaymentGateway(PaymentGateway.RAVEPAY)"
                              class="btn btn-accent" [class.is-loading]="isLoading"
                              [disabled]="isLoading">
                        <img src="assets/images/custom/flutter-pay.PNG" height="70px" width="240px">
                      </button>
                    </div><br>
                  </div>
                </div>


                <div class="list-group-item" *ngIf="authService.isLoggedIn()">
                  <div class="form-group row align-items-center mb-0">
                    <div class="col-sm-4">
                      <button  class="btn btn-accent"
                               [disabled]="isLoading2" (click)="payWithWalletInit()">
                        Pay with WALLET</button>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <!--electricity transaction summary-->
          <div class="col-lg-9 pr-lg-0" *ngIf="transactnSummaryDto.serviceName == PortalService.ELECTRICITY && !showToken">
            <div class="page-section">
              <div class="list-group list-group-form">
                <div class="list-group-item">
                  <div class="flex" style="font-size: x-large;">Transaction summary</div><br>

                  <div class="alert alert-accent" role="alert" *ngIf="notification != null">
                    <div class="d-flex flex-wrap align-items-start">
                      <div class="flex"
                           style="min-width: 180px">
                        <small class="text-black-100">
                          {{notification}}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="list-group-item">
                  <fieldset role="group"
                            aria-labelledby="label-type"
                            class="m-0 form-group">
                    <div class="form-row align-items-center">
                      <label
                        id="label-type"
                        class="col-md-3 col-form-label form-label">Service</label>
                      <div role="group"
                           aria-labelledby="label-type"
                           class="col-md-9">
                        <div role="group" class="btn-group btn-group-toggle">
                          <strong>Electricity payment</strong>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div class="list-group-item">
                  <fieldset role="group"
                            aria-labelledby="label-type"
                            class="m-0 form-group">
                    <div class="form-row align-items-center">
                      <label
                        id="label-type"
                        class="col-md-3 col-form-label form-label">Disco Name:</label>
                      <div role="group"
                           aria-labelledby="label-type"
                           class="col-md-9">
                        <div role="group" class="btn-group btn-group-toggle">
                          <strong>{{transactnSummaryDto.electricityTransaction.discoName | uppercase}}</strong>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>


                <div class="list-group-item">
                  <fieldset role="group"
                            aria-labelledby="label-type"
                            class="m-0 form-group">
                    <div class="form-row align-items-center">
                      <label
                        id="label-type1"
                        class="col-md-3 col-form-label form-label">Meter Number</label>
                      <div role="group"
                           aria-labelledby="label-type1"
                           class="col-md-9">
                        <div role="group" class="btn-group btn-group-toggle">
                          <strong>{{transactnSummaryDto.electricityTransaction.meterNo}}</strong>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>



                <div class="list-group-item">
                  <fieldset role="group"
                            aria-labelledby="label-type"
                            class="m-0 form-group">
                    <div class="form-row align-items-center">
                      <label
                        id="label-type2"
                        class="col-md-3 col-form-label form-label">Amount</label>
                      <div role="group"
                           aria-labelledby="label-type2"
                           class="col-md-9">
                        <div role="group" class="btn-group btn-group-toggle">
                          <strong>
                            <span class="badge badge-primary" style="font-size: large">
                              N{{transactnSummaryDto.electricityTransaction.amount}} +
                            N{{transactnSummaryDto.electricityTransaction.convinienceCharge}} (convenience charge)
                            </span>

                          </strong>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div class="list-group-item">
                  <fieldset role="group"
                            aria-labelledby="label-type"
                            class="m-0 form-group">
                    <div class="form-row align-items-center">
                      <label
                        id="label-type1"
                        class="col-md-3 col-form-label form-label">Phone number</label>
                      <div role="group"
                           aria-labelledby="label-type1"
                           class="col-md-9">
                        <div role="group" class="btn-group btn-group-toggle">
                          <strong>{{transactnSummaryDto.electricityTransaction.phoneNo}}</strong>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div class="list-group-item">
                  <fieldset role="group"
                            aria-labelledby="label-type"
                            class="m-0 form-group">
                    <div class="form-row align-items-center">
                      <label
                        id="label-type4"
                        class="col-md-3 col-form-label form-label">Transaction id</label>
                      <div role="group"
                           aria-labelledby="label-type4"
                           class="col-md-9">
                        <div role="group" class="btn-group btn-group-toggle">
                          <strong>
                            {{transactnSummaryDto.electricityTransaction.transactionId}}
                          </strong>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>


                <div class="list-group-item">
                  <fieldset role="group"
                            aria-labelledby="label-type"
                            class="m-0 form-group">
                    <div class="form-row align-items-center">
                      <label
                        id="label-type5"
                        class="col-md-3 col-form-label form-label">Transaction time</label>
                      <div role="group"
                           aria-labelledby="label-type5"
                           class="col-md-9">
                        <div role="group" class="btn-group btn-group-toggle">
                          <strong>
                            {{transactnSummaryDto.electricityTransaction.transactionTime}}
                          </strong>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div class="list-group-item">
                  <div class="form-group row align-items-center mb-0">
                    <div class="col-sm-4">
                      <button (click)="payWithPaymentGateway(PaymentGateway.RAVEPAY)"
                              class="btn btn-accent" [class.is-loading]="isLoading"
                              [disabled]="isLoading">
                        <img src="assets/images/custom/flutter-pay.PNG" height="70px" width="240px">
                      </button>
                    </div><br>
                  </div>
                </div>


                <div class="list-group-item" *ngIf="authService.isLoggedIn()">
                  <div class="form-group row align-items-center mb-0">
                    <div class="col-sm-4">
                      <button  class="btn btn-accent"
                               [disabled]="isLoading2" (click)="payWithWalletInit()">
                        Pay with WALLET</button>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <!--show token (after electricity purchase)-->
          <div class="col-lg-9 pr-lg-0" *ngIf="showToken">
            <div class="page-section">
              <div class="list-group list-group-form">
                <div class="list-group-item d-flex align-items-center">
                  <div class="flex" style="font-size: x-large;">ELECTRICITY TOKEN DETAILS</div>
                </div>


                <div class="list-group-item">
                  <fieldset role="group"
                            aria-labelledby="label-type"
                            class="m-0 form-group">
                    <div class="form-row align-items-center">
                      <label
                        id="label-type"
                        class="col-md-3 col-form-label form-label">NAME</label>
                      <div role="group"
                           aria-labelledby="label-type"
                           class="col-md-9">
                        <div role="group" class="btn-group btn-group-toggle">
                          <strong>{{tokenDetails.name}}</strong>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div class="list-group-item">
                  <fieldset role="group"
                            aria-labelledby="label-type"
                            class="m-0 form-group">
                    <div class="form-row align-items-center">
                      <label
                        id="label-type"
                        class="col-md-3 col-form-label form-label">ADDRESS</label>
                      <div role="group"
                           aria-labelledby="label-type"
                           class="col-md-9">
                        <div role="group" class="btn-group btn-group-toggle">
                          <strong>{{tokenDetails.address}}</strong>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div class="list-group-item">
                  <fieldset role="group"
                            aria-labelledby="label-type"
                            class="m-0 form-group">
                    <div class="form-row align-items-center">
                      <label
                        id="label-type"
                        class="col-md-3 col-form-label form-label">TOKEN</label>
                      <div role="group"
                           aria-labelledby="label-type"
                           class="col-md-9">
                        <div role="group" class="btn-group btn-group-toggle">
                          <strong>{{tokenDetails.token}}</strong>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div class="list-group-item">
                  <fieldset role="group"
                            aria-labelledby="label-type"
                            class="m-0 form-group">
                    <div class="form-row align-items-center">
                      <label
                        id="label-type"
                        class="col-md-3 col-form-label form-label">UNITS</label>
                      <div role="group"
                           aria-labelledby="label-type"
                           class="col-md-9">
                        <div role="group" class="btn-group btn-group-toggle">
                          <strong>{{tokenDetails.units}}</strong>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div class="list-group-item">
                  <fieldset role="group"
                            aria-labelledby="label-type"
                            class="m-0 form-group">
                    <div class="form-row align-items-center">
                      <label
                        id="label-type"
                        class="col-md-3 col-form-label form-label">DEBT ON METER</label>
                      <div role="group"
                           aria-labelledby="label-type"
                           class="col-md-9">
                        <div role="group" class="btn-group btn-group-toggle">
                          <strong>{{'N' + tokenDetails.debtAmount}}</strong>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>


              </div>
            </div>
          </div>




          <div class="col-lg-3 pr-lg-0" >
            <div class="page-section">
              <div class="list-group list-group-form">
                <img src="/assets/images/custom/summary.png" >
              </div>
            </div>
          </div>



        </div>

      </div>
    </div>

  </div>
</div>
