<!-------PRELOADER------------->
<div class="preloader">
  <div class="sk-chase">
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
  </div>
</div>


<!-------HEADER AND BODY------------->
<div class="mdk-drawer-layout js-mdk-drawer-layout" data-push data-responsive-width="992px">
  <div class="mdk-drawer-layout__content page-content">

    <app-header></app-header>


    <router-outlet></router-outlet>


    <div class="js-fix-footer footer border-top-2">
      <div class="container page__container page-section d-flex flex-column">
        <p class="text-70 brand mb-24pt">
          <img class="brand-icon"
               src="assets/images/logo/black-70@2x.png"
               width="30"
               alt="Huma"> Now Recharge
        </p>
        <p class="measure-lead-max text-muted mb-0 small">
          We've got you covered
        </p>
      </div>
      <div class="pb-16pt pb-lg-24pt">
        <div class="container page__container">
          <div class="bg-dark rounded page-section py-lg-32pt px-16pt px-lg-24pt">
            <div class="row">
              <div class="col-md-2 col-sm-4 mb-24pt mb-md-0">
                <p class="text-white-70 mb-8pt"><strong>Follow us</strong></p>
                <nav class="nav nav-links nav--flush">
                  <a
                     class="nav-link mr-8pt"><img src="assets/images/icon/footer/facebook-square@2x.png"
                                                  width="24"
                                                  height="24"
                                                  alt="Facebook"></a>
                  <a
                     class="nav-link mr-8pt"><img src="assets/images/icon/footer/twitter-square@2x.png"
                                                  width="24"
                                                  height="24"
                                                  alt="Twitter"></a>
                  <a
                     class="nav-link mr-8pt"><img src="assets/images/icon/footer/vimeo-square@2x.png"
                                                  width="24"
                                                  height="24"
                                                  alt="Vimeo"></a>
                  <!-- <a href="#" class="nav-link"><img src="assets/images/icon/footer/youtube-square@2x.png" width="24" height="24" alt="YouTube"></a> -->
                </nav>
              </div>
              <div class="col-md-6 col-sm-4 mb-24pt mb-md-0 d-flex align-items-center">

              </div>
              <div class="col-md-4 text-md-right">
                <p class="mb-8pt d-flex align-items-md-center justify-content-md-end">
                  <a
                     class="text-white-70 text-underline mr-16pt">Terms</a>
                  <a
                     class="text-white-70 text-underline">Privacy policy</a>
                </p>
                <p class="text-white-50 small mb-0">Copyright 2023 &copy; All rights reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>




  <!-------SIDE NAV------------->
  <div class="mdk-drawer js-mdk-drawer layout-compact__drawer" id="default-drawer">

    <div class="mdk-drawer__content js-sidebar-mini" data-responsive-width="992px" data-layout="compact">

      <app-side-nav></app-side-nav>

    </div>
  </div>




</div>

<!-- // END drawer-layout -->



