import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import * as $ from 'jquery';
import {PortalService} from '../../../constant/portal-service';
import {addImports} from '@angular/compiler-cli/src/ngtsc/transform/src/utils';
import {ServiceDetailsDto} from '../../../dto/service-details-dto';
import {ProductService} from '../../../service/product.service';
import {HttpErrorResponse} from '@angular/common/http';
import {AuthService} from '../../../service/auth/auth.service';
import {RoleConstant} from '../../../constant/role-constant.enum';
declare function showToast(msg): any;

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent implements OnInit {

  public RoleConstant = RoleConstant;
  public PortalService = PortalService;
  public serviceDetailsList: ServiceDetailsDto[] = [];
  public nestMenuControls = {
    airtime: false,
    data: false,
    electricity: false,
    tv: false,
    portal_user_menu: false,
    admin_menu: false
  };



  constructor(public productService: ProductService,
              public authService: AuthService) { }

  ngOnInit(): void {
    this.getAllElectricityServices();
  }

  showNestedMenu(menuType): void {

    this.nestMenuControls.airtime = false;
    this.nestMenuControls.data = false;
    this.nestMenuControls.electricity = false;
    this.nestMenuControls.tv = false;
    this.nestMenuControls.portal_user_menu = false;
    this.nestMenuControls.admin_menu = false;

    switch (menuType) {
      case PortalService.AIRTIME:
        this.nestMenuControls.airtime = true;
        break;

      case PortalService.ELECTRICITY:
        this.nestMenuControls.electricity = true;
        break;

      case PortalService.DATA:
        this.nestMenuControls.data = true;
        break;

      case PortalService.TV_SUBSCRIPTION:
        this.nestMenuControls.tv = true;
        break;

      case 'PORTAL_USER_MENU':
        this.nestMenuControls.portal_user_menu = true;
        break;

      case 'ADMIN_MENU':
        this.nestMenuControls.admin_menu = true;
        break;
    }

  }

  getAllElectricityServices(): void {
    this.productService.getAllElectricityServices()
      .subscribe(data => {
        if (data.data.length > 0) {
          data.data.forEach(s => {
            const sd = new ServiceDetailsDto();
            sd.mapToDto(s);
            this.serviceDetailsList.push(sd);
          });
        }
      }, error1 => {
        this.errorHandler(error1);
      });
  }

  // Error handling
  public errorHandler(error) {

    if (error instanceof HttpErrorResponse) {

      if (error.error.message) {
        // alert(error.error.message);
        return;
      }

      // alert('Network error');
      return;
    }
    // alert('Network error');
    return;
  }

}
