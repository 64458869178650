import { Component, OnInit } from '@angular/core';
import {TransactionService} from '../../../service/transaction.service';
import {PaginationDataDto} from '../../../dto/pagination-data-dto';
import {GeneralEnum} from '../../../constant/general-enum.enum';
import {PaginationService} from '../../../service/pagination.service';
import {HttpErrorResponse} from '@angular/common/http';
import {ServiceTransaction} from '../../../entity/service-transaction';
declare function showToast(msg): any;

@Component({
  selector: 'app-user-transactions',
  templateUrl: './user-transactions.component.html',
  styleUrls: ['./user-transactions.component.css']
})
export class UserTransactionsComponent implements OnInit {

  public transactions: ServiceTransaction[] = [];
  public paginationData = new PaginationDataDto();
  public isLoading = false;

  constructor(public transactionServc: TransactionService) { }

  ngOnInit(): void {
    this.initializePaginationData();
    this.getTransactions();
  }

  getTransactions(): void {
    this.isLoading = true;

    const defaultLimit = this.paginationData.defaultLimit;
    const currentPage = this.paginationData.page;

    // initialize pagination service
    const paginationServc = new PaginationService(defaultLimit, currentPage);

    // get offset and limit
    const offset = paginationServc.getOffset();
    const limit = paginationServc.getLimit();

    this.transactionServc.getUserDashboardTransactions(offset, limit)
      .subscribe(data =>  {

        this.transactions = [];
        this.paginationData.totalSize = data.data.totalSize;

        if (data.data.entities.length > 0) {

          data.data.entities.forEach(tr => {
            const transactn = new ServiceTransaction();
            transactn.mapToEntity(tr);
            this.transactions.push(transactn);
          });
        }

        this.isLoading = false;

      }, error1 => {
        this.isLoading = false;
        this.errorHandler(error1);
      });

  }


  initializePaginationData(): void {
    this.paginationData.page = 1;
    this.paginationData.defaultLimit = GeneralEnum.paginationLimit;
    this.paginationData.totalSize = 100;
  }


  // Error handling
  public errorHandler(error1) {

    if (error1 instanceof HttpErrorResponse) {

      if (error1.error.message) {
        showToast(error1.error.message);
        return;
      }

      showToast('Network error');
      return;
    }
    showToast('Network error');
    return;
  }
}
