import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {LoginDto} from '../../dto/login-dto';
import {GeneralEnum} from '../../constant/general-enum.enum';
import {GeneralUtil} from '../../util/general-util';
import {catchError} from 'rxjs/operators';
import {log} from 'util';
import {RegisterUserDto} from '../../dto/register-user-dto';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public static userDetailsEmail = 'USER-DETAILS-EMAIL';
  public static userDetailsFullName = 'USER-DETAILS-FULL-NAME';
  public static userDetailsRole = 'USER-DETAILS-ROLE';
  public static userDetailsToken = 'USER-DETAILS-TOKEN';
  public static userDetailsPhone = 'USER-DETAILS-PHONE';

  constructor(private httpClient: HttpClient) {
  }

  // Login user
  loginUser(loginDto: LoginDto): Observable<any> {

    const url = GeneralEnum.baseUrl + '/login';

    return this.httpClient.post<any>(url, loginDto)
      .pipe(catchError(this.errorHandler));
  }

  // Register a user
  registerUser(registerUserDto: RegisterUserDto): Observable<any> {

    const url = GeneralEnum.baseUrl + '/individual-users';

    return this.httpClient.post<any>(url, registerUserDto)
      .pipe(catchError(this.errorHandler));
  }

  // validate email
  validateEmailVerificationToken(token: string): Observable<any> {

    const url = GeneralEnum.baseUrl + '/validate-email-verification-token/' + token;

    return this.httpClient.patch<any>(url, null)
      .pipe(catchError(this.errorHandler));
  }

  // resend email validaiton
  resendEmailVerificationToken(email: string): Observable<any> {

    const url = GeneralEnum.baseUrl + '/resend-email-verification-token/' + email;

    return this.httpClient.patch<any>(url, null)
      .pipe(catchError(this.errorHandler));
  }

  // Confirm if user is logged in
  isLoggedIn() {
    return !!localStorage.getItem(AuthService.userDetailsToken);
  }

  // Get Token value
  getToken(): string {
    return localStorage.getItem(AuthService.userDetailsToken);
  }

  // Get Authorization Token
  getAuthToken(): string {
    if (this.getToken() == null) {
      return 'Bearer ';
    } else {
      return 'Bearer ' + this.getToken();
    }
  }

  // after login, set userdetails
  public setUserDetails(data: any): void {
    localStorage.setItem(AuthService.userDetailsEmail, data.email);
    localStorage.setItem(AuthService.userDetailsFullName, data.firstName + ' ' + data.lastName);
    localStorage.setItem(AuthService.userDetailsToken, data.accessToken);
    localStorage.setItem(AuthService.userDetailsRole, data.role);
    localStorage.setItem(AuthService.userDetailsPhone, data.phone);
  }

  // reset user details
  public resetUserDetails(): void {
    localStorage.removeItem(AuthService.userDetailsEmail);
    localStorage.removeItem(AuthService.userDetailsFullName);
    localStorage.removeItem(AuthService.userDetailsToken);
    localStorage.removeItem(AuthService.userDetailsRole);
    localStorage.removeItem(AuthService.userDetailsPhone);
  }

  public getUserDetailItem(userDetailItem: string): string {
    const userDetail = localStorage.getItem(userDetailItem);
    if (!GeneralUtil.isValidString(userDetail)) {
      return null;
    }
    return userDetail;
  }

  public setOneUserDetail(userDetailItem: string, value: string): void {
    const userDetail = localStorage.getItem(userDetailItem);

    if (GeneralUtil.isValidString(userDetail)) {
      localStorage.setItem(userDetailItem, value);
    }
  }

  // Error Handler
  errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }
}
