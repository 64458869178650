<div class="container page__container">
  <div class="page-section">

    <div class="row mb-lg-12pt">


      <!-------OVERVIEW OF NETWORKS-------->
      <div class="col-lg-12">

        <div class="page-separator">
          <div class="page-separator__text">Airtime</div>
        </div>

        <div class="row" *ngIf="selectedNetwork == 'all'">
          <div class="col-md-3">
            <div class="card " style="height: 150px">
              <div class="card-header pb-0 border-0 d-flex">
                <div class="flex">
                  <div class="h2 mb-0">Airtel</div>
                  <p class="mb-0"><strong>Topup</strong></p>
                </div>
                <!--<i class="material-icons text-50">screen_rotation</i>-->
                <img src="https://vtpass.com/resources/products/200X200/Airtel-Airtime.jpg" width="40" height="40">
              </div>
              <div class="card-body">

                <div class="text-center col-auto border-left">
                  <a (click)="selectedNetwork = airtimeNetwork.AIRTEL; refreshDto()"
                     class="btn btn-accent">Buy now</a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="card " style="height: 150px">
              <div class="card-header pb-0 border-0 d-flex">
                <div class="flex">
                  <div class="h2 mb-0">Mtn</div>
                  <p class="mb-0"><strong>Topup</strong></p>
                </div>
                <!--<i class="material-icons text-50">screen_rotation</i>-->
                <img src="https://vtpass.com/resources/products/200X200/MTN-Airtime.jpg" width="40" height="40">
              </div>
              <div class="card-body">

                <div class="text-center col-auto border-left">
                  <a (click)="selectedNetwork = airtimeNetwork.MTN; refreshDto()"
                     class="btn btn-accent">Buy now</a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="card " style="height: 150px">
              <div class="card-header pb-0 border-0 d-flex">
                <div class="flex">
                  <div class="h2 mb-0">Glo</div>
                  <p class="mb-0"><strong>Topup</strong></p>
                </div>
                <!--<i class="material-icons text-50">screen_rotation</i>-->
                <img src="https://vtpass.com/resources/products/200X200/GLO-Airtime.jpg" width="40" height="40">
              </div>
              <div class="card-body">

                <div class="text-center col-auto border-left">
                  <a (click)="selectedNetwork = airtimeNetwork.GLO; refreshDto()"
                     class="btn btn-accent">Buy now</a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="card " style="height: 150px">
              <div class="card-header pb-0 border-0 d-flex">
                <div class="flex">
                  <div class="h2 mb-0">9Mobile</div>
                  <p class="mb-0"><strong>Topup</strong></p>
                </div>
                <!--<i class="material-icons text-50">screen_rotation</i>-->
                <img src="https://vtpass.com/resources/products/200X200/9mobile-Airtime.jpg" width="40" height="40">
              </div>
              <div class="card-body">

                <div class="text-center col-auto border-left">
                  <a (click)="selectedNetwork = airtimeNetwork.ETISALAT; refreshDto()"
                     class="btn btn-accent">Buy now</a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>


      <!-----------AIRTEL RECHARGE------------>
      <div class="col-lg-12" *ngIf="selectedNetwork == airtimeNetwork.AIRTEL">

        <div class="row align-items-center mb-8pt">

          <div class="col-md-6">
            <div class="card card--raised">
              <div class="card-header d-flex align-items-center">
                <div class="flex">
                  <h4 class="card-title">Airtel</h4>
                  <p class="card-subtitle">Top up your airtel lines</p>
                </div>
                <img src="https://vtpass.com/resources/products/200X200/Airtel-Airtime.jpg" width="60" height="60">
              </div>
              <div class="card-body">
                <form #airtelForm="ngForm" novalidate (ngSubmit)="buyAirtime(airtimeNetwork.AIRTEL)">

                  <div class="form-group">
                    <label class="form-label" for="phoneA">Phone number</label>

                    <div class="input-group input-group-merge">
                      <input type="text"
                             class="form-control form-control-appended is-invalid" id="phoneA"
                             placeholder="Enter your phone number" name="phone" #phone="ngModel" required
                             [class.is-invalid]="phone.invalid && phone.touched"
                             [(ngModel)]="buyAirtimeDto.phone">
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="material-icons">phone_android</span>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="form-group">
                    <label class="form-label"
                           for="emailA">Email address</label>

                    <div class="input-group input-group-merge">
                      <input type="email"
                             class="form-control form-control-appended" required="" id="emailA"
                             pattern="^[^@]+@[^@]+\.[^@]+$"
                             placeholder="Enter your email" name="email" #email="ngModel" required
                             [class.is-invalid]="email.invalid && email.touched"
                             [(ngModel)]="buyAirtimeDto.email">
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="material-icons">alternate_email</span>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="form-group">
                    <label class="form-label"
                           for="amountA">Amount</label>

                    <div class="input-group input-group-merge">
                      <input type="number"
                             class="form-control form-control-appended" required="" id="amountA"
                             placeholder="Enter the recharge amount" name="amount" #amount="ngModel" required
                             [class.is-invalid]="amount.value < 50 && amount.touched"
                             [(ngModel)]="buyAirtimeDto.amount">
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="material-icons">label_important</span>
                        </div>
                      </div>
                    </div>
                  </div>


                  <button class="btn btn-accent" type="submit"
                          [class.is-loading]="isLoading"
                          [disabled]="airtelForm.form.invalid || isLoading">
                    BUY
                  </button>
                </form>
              </div>
            </div>

          </div>

        </div>

      </div>


      <!-----------MTN RECHARGE------------>
      <div class="col-lg-12" *ngIf="selectedNetwork == airtimeNetwork.MTN">

        <div class="row align-items-center mb-8pt">
          <div class="col-md-6">
            <div class="card card--raised">
              <div class="card-header d-flex align-items-center">
                <div class="flex">
                  <h4 class="card-title">MTN</h4>
                  <p class="card-subtitle">Top up your MTN lines</p>
                </div>
                <img src="https://vtpass.com/resources/products/200X200/MTN-Airtime.jpg" width="60" height="60">
              </div>
              <div class="card-body">
                <form #mtnForm="ngForm" novalidate (ngSubmit)="buyAirtime(airtimeNetwork.MTN)">

                  <div class="form-group">
                    <label class="form-label" for="phoneA">Phone number</label>

                    <div class="input-group input-group-merge">
                      <input type="text"
                             class="form-control form-control-appended is-invalid" id="phoneM"
                             placeholder="Enter your phone number" name="phoneM" #phoneM="ngModel" required
                             [class.is-invalid]="phoneM.invalid && phoneM.touched"
                             [(ngModel)]="buyAirtimeDto.phone">
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="material-icons">phone_android</span>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="form-group">
                    <label class="form-label"
                           for="emailA">Email address</label>

                    <div class="input-group input-group-merge">
                      <input type="email"
                             class="form-control form-control-appended" required="" id="emailM"
                             pattern="^[^@]+@[^@]+\.[^@]+$"
                             placeholder="Enter your email address" name="emailM" #emailM="ngModel" required
                             [class.is-invalid]="emailM.invalid && emailM.touched"
                             [(ngModel)]="buyAirtimeDto.email">
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="material-icons">alternate_email</span>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="form-group">
                    <label class="form-label"
                           for="amountA">Amount</label>

                    <div class="input-group input-group-merge">
                      <input type="number"
                             class="form-control form-control-appended" required="" id="amountM"
                             placeholder="Enter the recharge amount" name="amountM" #amountM="ngModel" required
                             [class.is-invalid]="amountM.value < 50 && amountM.touched"
                             [(ngModel)]="buyAirtimeDto.amount">
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="material-icons">label_important</span>
                        </div>
                      </div>
                    </div>
                  </div>


                  <button class="btn btn-accent"
                          [class.is-loading]="isLoading"
                          type="submit" [disabled]="mtnForm.form.invalid || isLoading">
                    BUY
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>

      </div>


      <!-----------GLO RECHARGE------------>
      <div class="col-lg-12" *ngIf="selectedNetwork == airtimeNetwork.GLO">

        <div class="row align-items-center mb-8pt">
          <div class="col-md-6">
            <div class="card card--raised">
              <div class="card-header d-flex align-items-center">
                <div class="flex">
                  <h4 class="card-title">GLO</h4>
                  <p class="card-subtitle">Top up your MTN line</p>
                </div>
                <img src="https://vtpass.com/resources/products/200X200/GLO-Airtime.jpg" width="60" height="60">
              </div>
              <div class="card-body">
                <form #gloForm="ngForm" novalidate (ngSubmit)="buyAirtime(airtimeNetwork.GLO)">

                  <div class="form-group">
                    <label class="form-label" for="phoneA">Phone number</label>

                    <div class="input-group input-group-merge">
                      <input type="text"
                             class="form-control form-control-appended is-invalid" id="phoneG"
                             placeholder="Enter your phone number" name="phoneG" #phoneG="ngModel" required
                             [class.is-invalid]="phoneG.invalid && phoneG.touched"
                             [(ngModel)]="buyAirtimeDto.phone">
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="material-icons">phone_android</span>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="form-group">
                    <label class="form-label"
                           for="emailA">Email address</label>

                    <div class="input-group input-group-merge">
                      <input type="email"
                             class="form-control form-control-appended" required="" id="emailG"
                             pattern="^[^@]+@[^@]+\.[^@]+$"
                             placeholder="Enter your email address" name="emailG" #emailG="ngModel"
                             [class.is-invalid]="emailG.invalid && emailG.touched"
                             [(ngModel)]="buyAirtimeDto.email">
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="material-icons">alternate_email</span>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="form-group">
                    <label class="form-label"
                           for="amountA">Amount</label>

                    <div class="input-group input-group-merge">
                      <input type="number"
                             class="form-control form-control-appended" required="" id="amountG"
                             placeholder="Enter the recharge amount" name="amountG" #amountG="ngModel"
                             [class.is-invalid]="amountG.value < 50 && amountG.touched"
                             [(ngModel)]="buyAirtimeDto.amount">
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="material-icons">label_important</span>
                        </div>
                      </div>
                    </div>
                  </div>


                  <button class="btn btn-accent" [class.is-loading]="isLoading"
                          type="submit" [disabled]="gloForm.form.invalid || isLoading">
                    BUY
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>

      </div>


      <!-----------9MOBILE RECHARGE------------>
      <div class="col-lg-12" *ngIf="selectedNetwork == airtimeNetwork.ETISALAT">

        <div class="row align-items-center mb-8pt">
          <div class="col-md-6">
            <div class="card card--raised">
              <div class="card-header d-flex align-items-center">
                <div class="flex">
                  <h4 class="card-title">9MOBILE</h4>
                  <p class="card-subtitle">Top up your 9MOBILE line</p>
                </div>
                <img src="https://vtpass.com/resources/products/200X200/9mobile-Airtime.jpg" width="60" height="60">
              </div>
              <div class="card-body">
                <form #nineMobileForm="ngForm" novalidate (ngSubmit)="buyAirtime(airtimeNetwork.ETISALAT)">

                  <div class="form-group">
                    <label class="form-label" for="phoneA">Phone number</label>

                    <div class="input-group input-group-merge">
                      <input type="text"
                             class="form-control form-control-appended is-invalid" id="phone9"
                             placeholder="Enter your phone number" name="phone9" #phone9="ngModel" required
                             [class.is-invalid]="phone9.invalid && phone9.touched"
                             [(ngModel)]="buyAirtimeDto.phone">
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="material-icons">phone_android</span>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="form-group">
                    <label class="form-label"
                           for="emailA">Email address</label>

                    <div class="input-group input-group-merge">
                      <input type="email"
                             class="form-control form-control-appended" required="" id="email9"
                             pattern="^[^@]+@[^@]+\.[^@]+$"
                             placeholder="Enter your email address" name="email9" #email9="ngModel"
                             [class.is-invalid]="email9.invalid && email9.touched"
                             [(ngModel)]="buyAirtimeDto.email">
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="material-icons">alternate_email</span>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="form-group">
                    <label class="form-label"
                           for="amountA">Amount</label>

                    <div class="input-group input-group-merge">
                      <input type="number"
                             class="form-control form-control-appended" required="" id="amount9"
                             placeholder="Enter the recharge amount" name="amount9" #amount9="ngModel"
                             [class.is-invalid]="amount9.value < 50 && amount9.touched"
                             [(ngModel)]="buyAirtimeDto.amount">
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="material-icons">label_important</span>
                        </div>
                      </div>
                    </div>
                  </div>


                  <button class="btn btn-accent" type="submit" [class.is-loading]="isLoading"
                          [disabled]="nineMobileForm.form.invalid || isLoading">
                    BUY
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>

  </div>
</div>
