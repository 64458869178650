import {GeneralUtil} from '../util/general-util';

export class ElectricityTransaction {
  public id: number;
  public discoName: string;
  public meterNo: string;
  public meterType: string;
  public amount: number;
  public convinienceCharge: number;
  public phoneNo: string;
  public email: string;
  public transactionId: string;
  public transactionTime: string;


  public mapToEntity(data: any): void {
    if (GeneralUtil.isValidJSON(data)) {
      this.id = data.id;
      this.discoName = data.discoName;
      this.meterNo = data.meterNo;
      this.meterType = data.meterType;
      this.amount = data.amount;
      this.convinienceCharge = data.convinienceCharge;
      this.phoneNo = data.phoneNo;
      this.email = data.email;
      this.transactionId = data.transactionId;
      this.transactionTime = GeneralUtil.getDateTime(data.transactionTime.timestamp);
    }
  }
}
