<div class="container page__container">
  <div class="page-section">

    <div class="row mb-lg-12pt">

      <!-----------TRANSACTION SUMMARY------------>
      <div class="col-lg-12">

        <div class="page-separator">
          <div class="page-separator__text">Please confirm your transaction details below</div>
        </div>

        <div class="row">

          <!--airtime transaction summary-->
          <div class="col-lg-9 pr-lg-0" >
            <div class="page-section">
              <div class="list-group list-group-form">

                <div class="list-group-item">
                  <div class="flex" style="font-size: x-large;">Transaction summary</div><br>

                  <div class="alert alert-accent" role="alert" *ngIf="notification != null">
                    <div class="d-flex flex-wrap align-items-start">
                      <div class="flex"
                           style="min-width: 180px">
                        <small class="text-black-100">
                          {{notification}}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="list-group-item">
                  <fieldset role="group"
                            aria-labelledby="label-type"
                            class="m-0 form-group">
                    <div class="form-row align-items-center">
                      <label
                        id="label-type"
                        class="col-md-3 col-form-label form-label">Service</label>
                      <div role="group"
                           aria-labelledby="label-type"
                           class="col-md-9">
                        <div role="group" class="btn-group btn-group-toggle">
                          <strong>Wallet Funding</strong>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div class="list-group-item">
                  <fieldset role="group"
                            aria-labelledby="label-type"
                            class="m-0 form-group">
                    <div class="form-row align-items-center">
                      <label
                        id="label-type1"
                        class="col-md-3 col-form-label form-label">Transaction ID</label>
                      <div role="group"
                           aria-labelledby="label-type1"
                           class="col-md-9">
                        <div role="group" class="btn-group btn-group-toggle">
                          <strong>{{initWalletCreditDto.transactionId}}</strong>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div class="list-group-item">
                  <fieldset role="group"
                            aria-labelledby="label-type"
                            class="m-0 form-group">
                    <div class="form-row align-items-center">
                      <label
                        id="label-type"
                        class="col-md-3 col-form-label form-label">Total amount</label>
                      <div role="group"
                           aria-labelledby="label-type"
                           class="col-md-9">
                        <div role="group" class="btn-group btn-group-toggle">
                          <strong>{{'N' + formatAmount(initWalletCreditDto.totalAmount)}}</strong>&nbsp;&nbsp;&nbsp;&nbsp;
                          <small>(This will include a service charge of 1.5%)</small>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div class="list-group-item">
                  <div class="form-group row align-items-center mb-0">
                    <div class="col-sm-4">
                      <button (click)="payWithPaymentGateway(PaymentGateway.RAVEPAY)"
                              class="btn btn-accent" [class.is-loading]="isLoading"
                              [disabled]="isLoading">
                        <img src="assets/images/custom/flutter-pay.PNG" height="70px" width="240px">
                      </button>
                    </div>


                  </div>
                </div>

              </div>
            </div>
          </div>




          <div class="col-lg-3 pr-lg-0" >
            <div class="page-section">
              <div class="list-group list-group-form">
                <img src="/assets/images/custom/phone-summary.PNG" >
              </div>
            </div>
          </div>



        </div>

      </div>
    </div>

  </div>
</div>
