import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {BuyAirtimeDto} from '../dto/buy-airtime-dto';
import {Observable, throwError} from 'rxjs';
import {GeneralEnum} from '../constant/general-enum.enum';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(public httpClient: HttpClient) { }

  public verifyPayment(txId: string): Observable<any> {
    const url = GeneralEnum.baseUrl + '/payment-verification/' + txId;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }


  public payWithWallet(txId: string): Observable<any> {
    const url = GeneralEnum.baseUrl + '/pay-with-wallet/' + txId;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  // Error Handler
  errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }
}
