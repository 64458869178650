import {GeneralUtil} from '../util/general-util';

export class VariationsDto {
  public variationCode: string;
  public name: string;
  public variationAmount: string;
  public fixedPrice: string;

  public mapToDto(data: any): void {
    if (GeneralUtil.isValidJSON(data)) {
      this.variationCode = data.variationCode;
      this.name = data.name;
      this.variationAmount = data.variationAmount;
      this.fixedPrice = data.fixedPrice;
    }
  }
}
