export class GeneralUtil {
  static getDate(timestamp) {
    const date = new Date(timestamp * 1000);

    // format
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';

    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutes2 = minutes < 10 ? '0' + minutes.toString() : minutes;
    const strTime = hours + ':' + minutes2 + ' ' + ampm;

    const month = this.getMonth(date.getMonth() + 1);
    return date.getDate() + ' ' + month + ' ' + date.getFullYear();
  }

  static getDateTime(timestamp) {
    const date = new Date(timestamp * 1000);

    // format
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';

    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutes2 = minutes < 10 ? '0' + minutes.toString() : minutes;
    const strTime = hours + ':' + minutes2 + ' ' + ampm;

    const month = this.getMonth(date.getMonth() + 1);
    return date.getDate() + ' ' + month + ' ' + date.getFullYear() + ' ' + strTime;
  }

  static getMonth(monthValue) {

    let month = null;

    switch (monthValue) {
      case 1:
        month = 'January';
        break;
      case 2:
        month = 'February';
        break;
      case 3:
        month = 'March';
        break;
      case 4:
        month = 'April';
        break;
      case 5:
        month = 'May';
        break;
      case 6:
        month = 'June';
        break;
      case 7:
        month = 'July';
        break;
      case 8:
        month = 'August';
        break;
      case 9:
        month = 'September';
        break;
      case 10:
        month = 'October';
        break;
      case 11:
        month = 'November';
        break;
      case 12:
        month = 'December';
        break;


    }
    return month;
  }

  static getRandomString(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  static isValidString(astring: any): boolean {
    if (astring !== undefined && astring !== '' && astring !== null) {
      return true;
    }
    return false;
  }

  static isValidJSON(json: any): boolean {
    if (json == null || json === '' || json === undefined) {
      return false;
    }

    const str = JSON.stringify(json);

    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }

    return true;
  }

  static convertDateStringToAge(date: string) {
    if (!this.isValidString(date)) {
      return null;
    }
    const age = date.split('-');
    const birthYear = Number(age[2]);
    const today = new Date();
    const thisYear = today.getFullYear();
    const year = (thisYear - birthYear);
    if (year === 1) {
      return '1 year';
    } else {
      return `${year} years`;
    }
  }

  static generateConsignmentId() {
    return 'CONS-' + this.getRandomString(8).toUpperCase();
  }

  /**
   * Convert 2000-08-12T13:06:46.631+01:00 datetimeformat to
   * 12-09-2000 dateformat
   */
  static convertDateTimeToDateformat(dateTime: string) {
    if (!this.isValidString(dateTime)) {
      return null;
    }

    const dateString = dateTime.split('T')[0];
    const dateArray = dateString.split('-');
    return dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0];
  }

  /**
   * sets the date string that comes from the server to
   * ISO 8601 Datetime Format: YYYY-MM-DDTHH:mm:ssTZ for ionic date-time component
   * */
  static convertDateStringToIsoDateTimeFormat(myDate: string) {
    const date = myDate.split('-');
    date.reverse();
    const dateString = new Date(date.toString());
    return dateString.toISOString();
  }

  static formatWithCommas(amount: number): string {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  static removeUnderscore(data: string): string {
    if (!this.isValidString(data)){
      return null;
    }

    return data.replace(/_/g, ' ');
  }


}
