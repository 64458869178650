<!---------------STATS------------------->
<div class="container page__container">
  <div class="page-section">

    <div class="col-lg-12" *ngIf="isLoading">
      <div style="max-width: 100%;">
        <div class="loader-list" align="center">
          <div class="loader loader-primary"></div>
        </div>
      </div>
    </div>

    <div class="page-separator">
      <div class="page-separator__text">Wallet</div>
    </div>

    <div class="row card-group-row mb-lg-8pt">
      <div class="col-xl-6 col-md-8 card-group-row__col">
        <div class="card card-group-row__card">
          <div class="card-body d-flex flex-column align-items-center">
            <i class="material-icons icon-32pt text-20 mb-4pt">account_balance_wallet</i>
            <div class="d-flex align-items-center">
              <div class="h3 mb-0 mr-3">{{'N' + formatAmount(walletDto.amount)}}</div>
              <div class="flex">
                <p class="mb-0"><strong>Wallet</strong></p>
                <p class="text-50 mb-0 mt-n1 d-flex align-items-center">
                  account
                </p>
              </div>
            </div>
            <div class="text-center col-auto border-left">
              <button (click)="openModal(fundWallet)"
                      class="chip chip-outline-secondary">Fund wallet now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="page-separator">
      <div class="page-separator__text">Wallet Transactions</div>
    </div>

    <div class="card mb-lg-32pt">

      <div class="table-responsive"
           data-toggle="lists"
           data-lists-sort-by="js-lists-values-employee-name"
           data-lists-values='["js-lists-values-employee-name", "js-lists-values-employer-name", "js-lists-values-projects", "js-lists-values-activity", "js-lists-values-earnings"]'>

        <div class="card-header">

        </div>

        <table class="table mb-0 thead-border-top-0 table-nowrap">

          <thead>
          <tr>

            <th>
              <a data-sort="js-lists-values-employee-name">Transaction ID</a>
            </th>

            <th style="width: 120px;">
              <a data-sort="js-lists-values-activity">Transaction Type</a>
            </th>

            <th style="width: 150px;">
              <a data-sort="js-lists-values-employer-name">Total Amount</a>
            </th>

            <th class="text-center"
                style="width: 48px;">
              <a data-sort="js-lists-values-projects">Date</a>
            </th>

            <th style="width: 37px;">Payment Status</th>

            <th style="width: 51px;">
              <a data-sort="js-lists-values-earnings">Value Status</a>
            </th>
            <th style="width: 24px;"
                class="pl-0"></th>
          </tr>
          </thead>


          <tbody class="list" id="staff">
          <tr class="selected" *ngFor="let transactn of walletTransactions">
            <td>
              <div class="d-flex align-items-center">
                <a class="text-70"><span class="js-lists-values-employer-name">
                  {{transactn.transactionId}}
                </span></a>
              </div>
            </td>


            <td class="text-center js-lists-values-projects small">{{transactn.transactionType}}</td>

            <td class="js-lists-values-earnings small">{{'N'+ transactn.amount}}</td>


            <td class="text-50 js-lists-values-activity small">{{transactn.regDate}}</td>

            <td>
              <a class="chip chip-outline-secondary" *ngIf="transactn.paymentStatus">
                {{transactn.paymentStatus}}</a>
            </td>


            <td class="js-lists-values-earnings small">{{transactn.valueStatus}}</td>
          </tr>
          </tbody>

        </table>
      </div>

      <div class="card-footer p-8pt">
        <ul class="pagination justify-content-start pagination-xsm m-0">
          <ngb-pagination [collectionSize]="paginationData.totalSize" [(page)]="paginationData.page"
                          (pageChange)="getWalletTransactions()"
                          [pageSize]="paginationData.defaultLimit" [maxSize]="5" [rotate]="true" [boundaryLinks]="false">

          </ngb-pagination>
        </ul>
      </div>

    </div>

  </div>
</div>


<ng-template #fundWallet let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      How much would you like to add to your wallet</h4>
    <button type="button" class="close" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <!--<label for="amount">How much would you like to add to your wallet</label>-->
        <div class="input-group">
          <input id="amount" class="form-control" placeholder="Amount" name="amount"
                 #amount="ngModel" [(ngModel)]="fundWalletAmount">
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <!--<button type="button" class="btn btn-outline-dark"
            [disabled]="fundWalletAmount < 1"
            (click)="fundWalletAction()" >Proceed</button>-->

    <button class="btn btn-accent" type="submit"
            [class.is-loading]="walletFundLoading"
            (click)="fundWalletAction(modal)"
            [disabled]="fundWalletAmount < 1">
      BUY
    </button>
  </div>
</ng-template>
