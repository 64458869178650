import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../service/auth/auth.service';
import {HttpErrorResponse} from '@angular/common/http';
declare function showToast(msg): any;

@Component({
  selector: 'app-resend-email-verification-token',
  templateUrl: './resend-email-verification-token.component.html',
  styleUrls: ['./resend-email-verification-token.component.css']
})
export class ResendEmailVerificationTokenComponent implements OnInit {

  // variables
  public email1: string;
  public isLoading = false;

  constructor(public authService: AuthService) { }

  ngOnInit(): void {
  }

  resendAction(): void {
    this.isLoading = true;

    this.authService.resendEmailVerificationToken(this.email1)
      .subscribe(data => {
        showToast('Email verification successfully sent. Please check your email');
        this.isLoading = false;
        return;
      }, error1 => {
        this.isLoading = false;
        this.errorHandler(error1);
      });
  }

  // Error handling
  public errorHandler(error): void {

    if (error instanceof HttpErrorResponse) {

      if (error.error.message) {
        showToast(error.error.message);
        return;
      }

      showToast('Network error');
      return;
    }
    showToast('Network error');
    return;
  }
}
