<!---------------TRANSACTIONS------------------->
<div class="container page__container">
  <div class="page-section">

    <div class="col-lg-12" *ngIf="isLoading">
      <div style="max-width: 100%;" >
        <div class="loader-list" align="center">
          <div class="loader loader-primary"></div>
        </div>
      </div>
    </div>


    <div class="page-separator">
      <div class="page-separator__text">Transactions</div>
    </div>

    <div class="card mb-lg-32pt">

      <div class="table-responsive"
           data-toggle="lists"
           data-lists-sort-by="js-lists-values-employee-name"
           data-lists-values='["js-lists-values-employee-name", "js-lists-values-employer-name", "js-lists-values-projects", "js-lists-values-activity", "js-lists-values-earnings"]'>

        <div class="card-header">

        </div>

        <table class="table mb-0 thead-border-top-0 table-nowrap">

          <thead>
            <tr>

              <th>
                <a data-sort="js-lists-values-employee-name">Transaction ID</a>
              </th>

              <th style="width: 120px;">
                <a data-sort="js-lists-values-activity">Service Name</a>
              </th>

              <th style="width: 150px;">
                <a data-sort="js-lists-values-employer-name">Total Fee</a>
              </th>

              <th class="text-center"
                  style="width: 48px;">
                <a data-sort="js-lists-values-projects">Date</a>
              </th>

              <th style="width: 37px;">Payment Status</th>

              <th style="width: 51px;">
                <a data-sort="js-lists-values-earnings">Value Status</a>
              </th>
              <th style="width: 24px;"
                  class="pl-0"></th>
            </tr>
          </thead>



          <tbody class="list" id="staff">
            <tr class="selected" *ngFor="let transactn of transactions">
              <td>
                <div class="d-flex align-items-center">
                  <a class="text-success" *ngIf="transactn.valueStatus == 'HAS VALUE'">
                    <i class="material-icons mr-8pt">thumb_up_alt</i>
                  </a>
                  <a class="text-warning" *ngIf="transactn.valueStatus !== 'HAS VALUE'">
                    <i class="material-icons mr-8pt">thumb_down_alt</i>
                  </a>

                  <a href=""
                     class="text-70"><span class="js-lists-values-employer-name">{{transactn.transactionId}}</span></a>
                </div>
              </td>


              <td class="text-center js-lists-values-projects small">{{transactn.portalService.name}}</td>

              <td class="js-lists-values-earnings small">{{'N'+ transactn.totalFee}}</td>


              <td class="text-50 js-lists-values-activity small">{{transactn.regDate}}</td>

              <td><a class="chip chip-outline-secondary">{{transactn.paymentStatus}}</a></td>


              <td class="js-lists-values-earnings small">{{transactn.valueStatus}}</td>
            </tr>
          </tbody>

        </table>
      </div>

      <div class="card-footer p-8pt">
        <ul class="pagination justify-content-start pagination-xsm m-0">
          <ngb-pagination [collectionSize]="paginationData.totalSize" [(page)]="paginationData.page"
                          (pageChange)="getTransactions()"
                          [pageSize]="paginationData.defaultLimit" [maxSize]="5" [rotate]="true" [boundaryLinks]="false">

          </ngb-pagination>
        </ul>
      </div>

    </div>

  </div>
</div>
