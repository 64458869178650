import {UserAccount} from './user-account';
import {GeneralUtil} from '../util/general-util';

export class PortalUser {
  public id: number;
  public firstName: string;
  public lastName: string;
  public userAccount = new UserAccount();

  public mapToEntity(data: any): void
  {
    if (GeneralUtil.isValidJSON(data)) {
      this.id = data.id;
      this.firstName = data.firstName;
      this.lastName = data.lastName;

      this.userAccount.mapToEntity(data.userAccount);
    }
  }
}
