import {Component, OnInit} from '@angular/core';
import {AirtimeNetwork} from '../../../constant/airtime-network.enum';
import {DataNetworkEnum} from '../../../constant/data-network-enum.enum';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {ProductService} from '../../../service/product.service';
import {GeneralUtil} from '../../../util/general-util';
import {TransactionSummaryDto} from '../../../entity/transaction-summary-dto';
import {BuyAirtimeDto} from '../../../dto/buy-airtime-dto';
import {BuyDataDto} from '../../../dto/buy-data-dto';
import {HttpErrorResponse} from '@angular/common/http';
import {ServiceWithVariationsDto} from '../../../dto/service-with-variations-dto';
import {VariationsDto} from '../../../dto/variations-dto';
declare function showToast(msg): any;

@Component({
  selector: 'app-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.css']
})
export class DataComponent implements OnInit {

  public selectedNetwork: any;
  public dataNetwork = DataNetworkEnum;
  public buyDataDto = new BuyDataDto();

  public airtelDataVariations: VariationsDto[] = [];
  public mtnDataVariations: VariationsDto[] = [];
  public gloDataVariations: VariationsDto[] = [];
  public etisalatDataVariations: VariationsDto[] = [];

  public isLoading1 = true;
  public isLoading2 = false;

  constructor(public route: ActivatedRoute,
              public router: Router,
              public productService: ProductService) {
  }

  ngOnInit(): void {
    // fetch network-selection
    this.route.paramMap.subscribe((params: ParamMap) => {
      const selectedNetwork = params.get('network-selection');

      if (GeneralUtil.isValidString(selectedNetwork)) {
        this.selectedNetwork = selectedNetwork;
      }
    });

    this.getDataVariations();
  }


  getDataVariations(): void {
    this.productService.getDataVariationsForAllNetworks()
      .subscribe(data => {
        if (data.data.length > 0) {

          // get all service-with-variations
          const services: ServiceWithVariationsDto[] = [];

          data.data.forEach(s => {
            const service = new ServiceWithVariationsDto();
            service.mapToDto(s);
            services.push(service);
          });

          // get all variatons by networks (airtel, mtn etc)
          // 1. airtel
          const airtelService = services.filter(s => {
            return s.serviceID === DataNetworkEnum.AIRTEL_DATA;
          })[0];

          if (GeneralUtil.isValidJSON(airtelService)) {
            this.airtelDataVariations = airtelService.variations;
          }


          // 2. mtn
          const mtnService = services.filter(s => {
            return s.serviceID === DataNetworkEnum.MTN_DATA;
          })[0];

          if (GeneralUtil.isValidJSON(mtnService)) {
            this.mtnDataVariations = mtnService.variations;
          }


          // 3. gop
          const gloService = services.filter(s => {
            return s.serviceID === DataNetworkEnum.GLO_DATA;
          })[0];

          if (GeneralUtil.isValidJSON(gloService)) {
            this.gloDataVariations = gloService.variations;
          }


          // 4. 9mobile
          const etisalatService = services.filter(s => {
            return s.serviceID === DataNetworkEnum.ETISALAT_DATA;
          })[0];

          if (GeneralUtil.isValidJSON(etisalatService)) {
            this.etisalatDataVariations = etisalatService.variations;
          }

          this.isLoading1 = false;
        }
      },
        error1 => {
        this.isLoading1 = true;
        this.errorHandler(error1);
      });
  }


  buyData(network: DataNetworkEnum): void {
    this.buyDataDto.networkType = network;
    this.isLoading2 = true;

    this.productService.initializeDataPurchase(this.buyDataDto)
      .subscribe(data => {
          const transactn = new TransactionSummaryDto();
          transactn.mapToEntity(data.data);
          localStorage.setItem('TRANSACTION_SUMMARY', JSON.stringify(transactn));

          this.isLoading2 = false;

          this.router.navigate(['/transaction-summary']);
          return;
        },
        error1 => {
          this.isLoading2 = false;
          this.errorHandler(error1);
        });

  }

  refreshDto(): void {
    this.buyDataDto = new BuyDataDto();
  }


  // Error handling
  public errorHandler(error) {

    if (error instanceof HttpErrorResponse) {

      if (error.error.message) {
        showToast(error.error.message);
        return;
      }

      showToast('Network error');
      return;
    }
    showToast('Network error');
    return;
  }


}
