import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {GeneralEnum} from '../constant/general-enum.enum';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  constructor(public httpClient: HttpClient) { }

  public getUserDashboardStats(): Observable<any> {
    const url = GeneralEnum.baseUrl + '/user-dashboard/stats';

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }


  public getUserDashboardTransactions(offset: number, limit: number): Observable<any> {
    const url = GeneralEnum.baseUrl + '/transactions/user?offset=' + offset
       + '&limit=' + limit;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }


  public getAdminDashboardStats(): Observable<any> {
    const url = GeneralEnum.baseUrl + '/admin/dashboard-stats';

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  public getAllTransactions(offset: number, limit: number, valueStatus: string,
                            paymentStatus: string, portalServiceId: number, search: string): Observable<any> {
    const url = GeneralEnum.baseUrl + '/admin/transactions/all?offset=' + offset
      + '&limit=' + limit + '&valueStatus=' + valueStatus + '&paymentStatus=' + paymentStatus
      + '&portalServiceId=' + portalServiceId + '&search=' + search;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }


  // Error Handler
  errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }
}
