import { Component, OnInit } from '@angular/core';
import {TransactionService} from '../../../service/transaction.service';
import {HttpErrorResponse} from '@angular/common/http';
import {error} from 'selenium-webdriver';
declare function showToast(msg): any;

@Component({
  selector: 'app-u-dashboard',
  templateUrl: './u-dashboard.component.html',
  styleUrls: ['./u-dashboard.component.css']
})
export class UDashboardComponent implements OnInit {

  public isLoading = false;
  public stats = {
    airtimeCount: 0,
    dataCount: 0,
    tvCount: 0,
    electricityCount: 0
  };

  constructor(public transactionService: TransactionService) { }

  ngOnInit(): void {
    this.getStats();
  }

  getStats(): void {
    this.isLoading = true;

    this.transactionService.getUserDashboardStats()
      .subscribe(data => {

        this.stats.airtimeCount = data.data.airtimeCount;
        this.stats.dataCount = data.data.dataCount;
        this.stats.tvCount = data.data.tvCount;
        this.stats.electricityCount = data.data.electricityCount;
        this.isLoading = false;

      }, error1 => {
        this.isLoading = false;
        this.errorHandler(error1);
  });
  }


  // Error handling
  public errorHandler(error1) {

    if (error1 instanceof HttpErrorResponse) {

      if (error1.error.message) {
        showToast(error1.error.message);
        return;
      }

      showToast('Network error');
      return;
    }
    showToast('Network error');
    return;
  }

}
