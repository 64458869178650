import {GeneralUtil} from '../util/general-util';

export class TvTransaction {
  public id: number;
  public tvType: string;
  public amount: number;
  public phoneNumber: string;
  public email: string;
  public description: string;
  public transactionId: string;
  public transactionTime: string;
  public convinienceCharge: number;
  public bouqetDetails: string;
  public variationCode: string;
  public decoderNo: string;


  public mapToEntity(data: any): void {
    if (GeneralUtil.isValidJSON(data)) {
      this.id = data.id;
      this.tvType = data.tvType;
      this.amount = data.amount;
      this.phoneNumber = data.phoneNumber;
      this.email = data.email;
      this.description = data.description;
      this.transactionId = data.transactionId;
      this.transactionTime = GeneralUtil.getDateTime(data.transactionTime.timestamp);
      this.convinienceCharge = data.convinienceCharge;
      this.bouqetDetails = data.bouqetDetails;
      this.variationCode = data.variationCode;
      this.decoderNo = data.decoderNo;
    }
  }
}
