import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {InitWalletCredit} from '../../../../dto/init-wallet-credit';
import {GeneralUtil} from '../../../../util/general-util';
import {Router} from '@angular/router';
import {PaymentService} from '../../../../service/payment.service';
import {Flutterwave, InlinePaymentOptions, PaymentSuccessResponse} from 'flutterwave-angular-v3';
import {PaymentGatewayService} from '../../../../service/payment-gateway.service';
import {PaymentGatewayEntity} from '../../../../entity/payment-gateway-entity';
import {HttpErrorResponse} from '@angular/common/http';
import {PaymentGateway} from '../../../../constant/payment-gateway.enum';
import {CustomerDetailDto} from '../../../../dto/customer-detail-dto';
import {AuthService} from '../../../../service/auth/auth.service';
import {WalletService} from '../../../../service/wallet.service';

declare function showToast(msg): any;

@Component({
  selector: 'app-fund-wallet',
  templateUrl: './fund-wallet.component.html',
  styleUrls: ['./fund-wallet.component.css']
})
export class FundWalletComponent implements OnInit {

  public isLoading = false;
  public initWalletCreditDto = new InitWalletCredit();
  public paymentGateways: PaymentGatewayEntity[] = [];
  public notification = null;
  public paymentData: InlinePaymentOptions;
  public PaymentGateway = PaymentGateway;

  constructor(public router: Router,
              public walletService: WalletService,
              public paymentService: PaymentService,
              public authService: AuthService,
              public flutterwave: Flutterwave,
              public paymentGatewayServc: PaymentGatewayService,
              public cdf: ChangeDetectorRef) {
  }

  ngOnInit(): void {

    // get init-wallet-credit object
    this.initWalletCreditDto = JSON.parse(sessionStorage.getItem('INIT_WALLET_CREDIT_DTO'));

    if (this.initWalletCreditDto == null || !GeneralUtil.isValidString(this.initWalletCreditDto.transactionId)) {
      this.router.navigate(['/user-dashboard/wallet']);
      return;
    }

    this.getAllPaymentGateways();
  }


  getAllPaymentGateways(): void {
    this.paymentGatewayServc.getPaymentGateways()
      .subscribe(data => {
        if (data.data.length > 0) {
          data.data.forEach(p => {
            const pg = new PaymentGatewayEntity();
            pg.mapToEntity(p);
            this.paymentGateways.push(pg);
          });
        }
      }, error1 => this.errorHandler(error1));
  }

  payWithPaymentGateway(pg: PaymentGateway): void {
    this.isLoading = true;


    switch (pg) {
      case PaymentGateway.PAYSTACK:
        showToast('Not available for now');
        this.isLoading = false;
        return;

      case PaymentGateway.RAVEPAY:
        this.payWithRavePay();
        break;
    }
  }

  payWithRavePay(): void {

    // set up paymentData
    // 1.
    const pg = this.paymentGateways.filter(p => {
      return p.name === PaymentGateway.RAVEPAY;
    })[0];

    if (pg == null) {
      showToast('Rave payment not available at the moment');
      this.isLoading = false;
      return;
    }


    const publicKey = pg.publicKey;

    const customizatns = {
      title: 'Now recharge',
      description: 'Easy payments for all utility bills',
      logo: 'https://flutterwave.com/images/logo-colored.svg'
    };            // TODO: change to nowrecharge logo && let it be dynamic

    const customerDetails = new CustomerDetailDto();

    customerDetails.name = this.authService.getUserDetailItem(AuthService.userDetailsFullName);
    customerDetails.email = this.authService.getUserDetailItem(AuthService.userDetailsEmail);
    customerDetails.phone_number = this.authService.getUserDetailItem(AuthService.userDetailsPhone);


    // 2.
    this.paymentData = {
      public_key: publicKey,
      tx_ref: this.initWalletCreditDto.transactionId,
      amount: this.initWalletCreditDto.totalAmount,
      currency: 'NGN',
      payment_options: 'card,ussd',
      redirect_url: '',
      meta: '',
      customer: customerDetails,
      customizations: customizatns,
      callback: this.flutterWavePaymentCallback,
      onclose: this.closedPaymentModal,
      callbackContext: this
    };

    // go to payment gateway
    this.flutterwave.inlinePay(this.paymentData);
  }


  flutterWavePaymentCallback(response: PaymentSuccessResponse): void {
    this.flutterwave.closePaymentModal(2);

    // if payment is not successful
    if (response.status !== 'successful') {
      showToast('Your payment was NOT successful. Please try again');
      this.isLoading = false;
      this.cdf.detectChanges();
      return;
    }

    // if payment is successful, verify it
    this.walletService.completeWalletCreditTransaction(PaymentGateway.RAVEPAY, response.tx_ref)
      .subscribe(data => {
        showToast(data.data.message);
        this.notification = data.data.message;

        this.isLoading = false;
        setTimeout(() => {
          sessionStorage.removeItem('INIT_WALLET_CREDIT_DTO');
          window.location.reload();
        }, 1000);


        // TODO: delete the transactnSummaryDto from localstorage
      }, error1 => {
        this.isLoading = false;
        this.cdf.detectChanges();
        this.errorHandler(error1);
      });
    this.cdf.detectChanges();
  }

  closedPaymentModal(): void {

  }

  formatAmount(amount: number): string {
    return GeneralUtil.formatWithCommas(amount);
  }


  public errorHandler(error) {

    if (error instanceof HttpErrorResponse) {

      if (error.error.message) {
        showToast(error.error.message);
        return;
      }

      showToast('Network error');
      return;
    }
    showToast('Network error');
    return;
  }

}
