<div class=" pt-32pt pt-sm-64pt pb-32pt">
  <div class="container-fluid page__container">
    <form #loginForm="ngForm" novalidate (ngSubmit)="loginAction()" class="col-md-5 p-0 mx-auto">
      <div class="form-group">

        <label class="form-label" for="email">Email:</label>

        <input id="email" type="email" class="form-control" name="email" required
               #email="ngModel" [(ngModel)]="loginDto.email" pattern="^[^@]+@[^@]+\.[^@]+$"
               [class.is-invalid]="email.invalid && email.touched"
               placeholder="Your email address ...">
      </div>


      <div class="form-group">
        <label class="form-label" for="password">Password:</label>

        <input id="password" type="password" class="form-control" required
               name="password" #password="ngModel" [(ngModel)]="loginDto.password"
               [class.is-invalid]="password.invalid && password.touched"
               placeholder="Your password ...">
        <p class="text-right"><a class="small">Forgot your password?</a></p>
      </div>
      <div class="text-center">
        <button class="btn btn-accent" type="submit"
                [class.is-loading]="isLoading"
                [disabled]="loginForm.invalid">
          Login
        </button>
      </div>
    </form>
  </div>
</div>
<div class="page-separator justify-content-center m-0">
  <div class="page-separator__text">OR</div>
  <div class="page-separator__bg-top "></div>
</div>
<div class="bg-body pt-32pt pb-32pt pb-md-64pt text-center">
  <div class="container-fluid page__container">
    <a routerLink="/user-registration"
       class="btn btn-secondary btn-block-xs">Register</a>
  </div>
</div>
