import {VariationsDto} from './variations-dto';
import {GeneralUtil} from '../util/general-util';

export class ServiceWithVariationsDto {
  public ServiceName: string;
  public serviceID: string;
  public variations: VariationsDto[] = [];

  public mapToDto(data: any): void {
    if (GeneralUtil.isValidJSON(data)) {
      this.ServiceName = data.ServiceName;
      this.serviceID = data.serviceID;

      if (data.variations.length > 0) {
        data.variations.forEach(v => {
          const variation = new VariationsDto();
          variation.mapToDto(v);
          this.variations.push(variation);
        });

      }
    }
  }
}
