import {GeneralUtil} from '../util/general-util';

export class PortalService {
  public id: number;
  public name: string;
  public description: string;

  public mapToEntity(data: any): void
  {
    if (GeneralUtil.isValidJSON(data)) {
      this.id = data.id;
      this.name = data.name;
      this.description = data.description;
    }
  }
}
