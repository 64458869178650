<div class="border-bottom-2 py-32pt position-relative z-1">
  <div class="container page__container d-flex flex-column flex-md-row align-items-center text-center text-sm-left">
    <div class="flex d-flex flex-column flex-sm-row align-items-center mb-24pt mb-md-0">

      <div class="mb-24pt mb-sm-0 mr-sm-24pt">
        <h2 class="mb-0">Now Recharge</h2>

        <ol class="breadcrumb p-0 m-0">
          <p>INSTANT is our policy</p>
        </ol>
        <ol class=" p-0 m-0">
          <i>Top up phone airtime or internet data. Pay electricity bills; renew TV subscriptions.
             Pay education bills, transfer funds and do more </i>
        </ol>

      </div>


    </div>

    <div class="row" role="tablist">
      <!--<img src="https://vtpass.com/resources/frontend3/images/homepage1.png">-->
      <img src="/assets/images/custom/landing.svg" height="300px">
    </div>

  </div>
</div>



<!---------------SERVICE OVERVIEW------------------->
<div class="container page__container">
  <div class="page-section">

    <div class="row mb-lg-12pt">
      <div class="col-lg-12">

        <div class="page-separator">
          <div class="page-separator__text">Overview</div>
        </div>

        <div class="row">

          <div class="col-md-3">
            <div class="card " style="height: 290px">
              <div class="card-header pb-0 border-0 d-flex">
                <div class="flex">
                  <div class="h2 mb-0">Airtime</div>
                  <!--<p class="mb-0"><strong>Airtime</strong></p>-->
                </div>
                <i class="material-icons text-50">screen_rotation</i>
              </div>
              <div class="card-body">
                <div class="text-50 mb-16pt">

                  <div class="mb-4pt">
                    <p class="d-flex align-items-center mb-0">
                      <small class="flex lh-24pt">Airtel</small>
                      <small class="lh-24pt">
                        <i class="material-icons text-50">done</i>
                      </small>
                    </p>
                  </div>

                  <div class="mb-4pt">
                    <p class="d-flex align-items-center mb-0">
                      <small class="flex lh-24pt">Mtn</small>
                      <small class="lh-24pt">
                        <i class="material-icons text-50">done</i>
                      </small>
                    </p>
                  </div>

                  <div class="mb-4pt">
                    <p class="d-flex align-items-center mb-0">
                      <small class="flex lh-24pt">Glo</small>
                      <small class="lh-24pt">
                        <i class="material-icons text-50">done</i>
                      </small>
                    </p>
                  </div>

                  <div class="mb-4pt">
                    <p class="d-flex align-items-center mb-0">
                      <small class="flex lh-24pt">9Mobile</small>
                      <small class="lh-24pt">
                        <i class="material-icons text-50">done</i>
                      </small>
                    </p>
                  </div>

                </div>

                <div class="text-center col-auto border-left">
                  <a routerLink="/services/airtime/all"
                     class="btn btn-accent">Buy now</a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="card " style="height: 290px">
              <div class="card-header pb-0 border-0 d-flex">
                <div class="flex">
                  <div class="h2 mb-0">Data</div>
                  <!--<p class="mb-0"><strong>Internet/Data</strong></p>-->
                </div>
                <i class="material-icons text-50">donut_large</i>
              </div>
              <div class="card-body">
                <div class="text-50 mb-16pt">

                  <div class="mb-4pt">
                    <p class="d-flex align-items-center mb-0">
                      <small class="flex lh-24pt">Airtel Data</small>
                      <small class="lh-24pt">
                        <i class="material-icons text-50">done</i>
                      </small>
                    </p>
                  </div>

                  <div class="mb-4pt">
                    <p class="d-flex align-items-center mb-0">
                      <small class="flex lh-24pt">Mtn Data</small>
                      <small class="lh-24pt">
                        <i class="material-icons text-50">done</i>
                      </small>
                    </p>
                  </div>

                  <div class="mb-4pt">
                    <p class="d-flex align-items-center mb-0">
                      <small class="flex lh-24pt">Glo Data</small>
                      <small class="lh-24pt">
                        <i class="material-icons text-50">done</i>
                      </small>
                    </p>
                  </div>

                  <div class="mb-4pt">
                    <p class="d-flex align-items-center mb-0">
                      <small class="flex lh-24pt">9Mobile Data</small>
                      <small class="lh-24pt">
                        <i class="material-icons text-50">done</i>
                      </small>
                    </p>
                  </div>

                </div>
                <div class="text-center col-auto border-left">
                  <a routerLink="/services/data/all"
                     class="btn btn-accent">Buy now</a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-3" >
            <div class="card" style="height: 290px">
              <div class="card-header pb-0 border-0 d-flex">
                <div class="flex">
                  <div class="h2 mb-0">TV</div>
                  <p class="mb-0"><strong>Subscription</strong></p>
                </div>
                <i class="material-icons text-50">reset_tv</i>
              </div>
              <div class="card-body">
                <div class="text-50 mb-16pt">

                  <div class="mb-4pt">
                    <p class="d-flex align-items-center mb-0">
                      <small class="flex lh-24pt">DSTV</small>
                      <small class="lh-24pt">
                        <i class="material-icons text-50">done</i>
                      </small>
                    </p>
                  </div>

                  <div class="mb-4pt">
                    <p class="d-flex align-items-center mb-0">
                      <small class="flex lh-24pt">Go TV</small>
                      <small class="lh-24pt">
                        <i class="material-icons text-50">done</i>
                      </small>
                    </p>
                  </div>

                  <div class="mb-4pt">
                    <p class="d-flex align-items-center mb-0">
                      <small class="flex lh-24pt">Startimes</small>
                      <small class="lh-24pt">
                        <i class="material-icons text-50">done</i>
                      </small>
                    </p>
                  </div>

                </div>
                <div class="text-center col-auto border-left">
                  <a routerLink="/services/tv/all"
                     class="btn btn-accent">Buy now</a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="card " style="height: 290px">
              <div class="card-header pb-0 border-0 d-flex">
                <div class="flex">
                  <div class="h2 mb-0">Electricity</div>
                  <!--<p class="mb-0"><strong>Electricity</strong></p>-->
                </div>
                <i class="material-icons text-50">lightbulb</i>
              </div>
              <div class="card-body">
                <div class="text-50 mb-16pt">

                  <div class="mb-4pt">
                    <p class="d-flex align-items-center mb-0">
                      <small class="flex lh-24pt">AEDC</small>
                      <small class="lh-24pt">
                        <i class="material-icons text-50">done</i>
                      </small>
                    </p>
                  </div>

                  <div class="mb-4pt">
                    <p class="d-flex align-items-center mb-0">
                      <small class="flex lh-24pt">Ikeja Disco</small>
                      <small class="lh-24pt">
                        <i class="material-icons text-50">done</i>
                      </small>
                    </p>
                  </div>

                  <div class="mb-4pt">
                    <p class="d-flex align-items-center mb-0">
                      <small class="flex lh-24pt">PH Disco</small>
                      <small class="lh-24pt">
                        <i class="material-icons text-50">done</i>
                      </small>
                    </p>
                  </div>

                  <div class="mb-4pt">
                    <p class="d-flex align-items-center mb-0">
                      <small class="flex lh-24pt">and others</small>
                      <small class="lh-24pt">
                        <i class="material-icons text-50">done</i>
                      </small>
                    </p>
                  </div>

                </div>
                <div class="text-center col-auto border-left">
                  <a routerLink="/services/electricity"
                     class="btn btn-accent">Buy now</a>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>

  </div>
</div>
