import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {AuthService} from '../../service/auth/auth.service';
import {HttpErrorResponse} from '@angular/common/http';
import {fakeAsync} from '@angular/core/testing';
declare function showToast(msg): any;

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.css']
})
export class EmailVerificationComponent implements OnInit {

  // parameters
  public isValidated = false;
  public errorInValidation = false;
  public validationErrorMsg: string = null;
  public isLoading1 = true;

  constructor(public route: ActivatedRoute,
              public authService: AuthService) { }

  ngOnInit(): void {
    this.validateEmailToken();
  }

  validateEmailToken(): void {

    let token: string = null;

    this.route.paramMap.subscribe((params: ParamMap) => {
      token = params.get('validation-token');
    });

    this.authService.validateEmailVerificationToken(token)
      .subscribe(data => {
        this.isValidated = true;
        this.isLoading1 = false;
      },

        error1 => {
          this.errorInValidation = true;
          this.validationErrorMsg = error1.error.message;
          this.isLoading1 = false;
        });
  }


  // Error handling
  public errorHandler(error): void {

    if (error instanceof HttpErrorResponse) {

      if (error.error.message) {
        showToast(error.error.message);
        return;
      }

      showToast('Network error');
      return;
    }
    showToast('Network error');
    return;
  }



}
