import {GeneralUtil} from '../util/general-util';

export class ServiceDetailsDto {
  public serviceID: string;
  public name: string;
  public minimiumAmount: number;
  public maximumAmount: number;
  public convinienceFee: string;
  public productType: string;


  public mapToDto(data: any): void {
    if (GeneralUtil.isValidJSON(data)) {
      this.serviceID = data.serviceID;
      this.name = data.name;
      this.minimiumAmount = data.minimiumAmount;
      this.maximumAmount = data.maximumAmount;
      this.convinienceFee = data.convinienceFee;
      this.productType = data.productType;
    }
  }
}
