import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './component/home/home.component';
import { HeaderComponent } from './component/tag/header/header.component';
import { SideNavComponent } from './component/tag/side-nav/side-nav.component';
import { AirtimeComponent } from './component/services/airtime/airtime.component';
import {FormsModule} from '@angular/forms';
import { TransactionSummaryComponent } from './component/transaction-summary/transaction-summary.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FlutterwaveModule} from 'flutterwave-angular-v3';
import { DataComponent } from './component/services/data/data.component';
import { TvComponent } from './component/services/tv/tv.component';
import { ElectricityComponent } from './component/services/electricity/electricity.component';
import {TokenInterceptorService} from './service/auth/token-interceptor.service';
import {AuthGuard} from './service/auth/auth.guard';
import {AuthService} from './service/auth/auth.service';
import { LoginComponent } from './component/login/login.component';
import { UDashboardComponent } from './component/userDashboard/u-dashboard/u-dashboard.component';
import { UserTransactionsComponent } from './component/userDashboard/user-transactions/user-transactions.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UserWalletComponent } from './component/userDashboard/user-wallet/user-wallet.component';
import { FundWalletComponent } from './component/userDashboard/user-wallet/fund-wallet/fund-wallet.component';
import { ADashboardComponent } from './component/adminDashboard/a-dashboard/a-dashboard.component';
import { ATransactionsComponent } from './component/adminDashboard/a-transactions/a-transactions.component';
import { UserRegistrationComponent } from './component/user-registration/user-registration.component';
import { EmailVerificationComponent } from './component/email-verification/email-verification.component';
import { ResendEmailVerificationTokenComponent } from './component/resend-email-verification-token/resend-email-verification-token.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    SideNavComponent,
    AirtimeComponent,
    TransactionSummaryComponent,
    DataComponent,
    TvComponent,
    ElectricityComponent,
    LoginComponent,
    UDashboardComponent,
    UserTransactionsComponent,
    UserWalletComponent,
    FundWalletComponent,
    ADashboardComponent,
    ATransactionsComponent,
    UserRegistrationComponent,
    EmailVerificationComponent,
    ResendEmailVerificationTokenComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    FlutterwaveModule,
    NgbModule
  ],
  providers: [
    AuthGuard,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
