<div class="container page__container">
  <div class="page-section">

    <div class="row mb-lg-12pt">


      <!-------OVERVIEW OF TVS-------->
      <div class="col-lg-12">

        <div class="page-separator">
          <div class="page-separator__text">TV selections</div>
        </div>

        <div class="row" *ngIf="selectedTvType == 'all'">
          <div class="col-md-3">
            <div class="card " style="height: 150px">
              <div class="card-header pb-0 border-0 d-flex">
                <div class="flex">
                  <div class="h2 mb-0">DSTV</div>
                  <!--<p class="mb-0"><strong>Data</strong></p>-->
                </div>
                <img src="https://vtpass.com/resources/products/200X200/Pay-DSTV-Subscription.jpg" width="40" height="40">
              </div>
              <div class="card-body">

                <div class="text-center col-auto border-left">
                  <a (click)="selectedTvType = tvType.DSTV; refreshDto()"
                     class="btn btn-accent">Buy now</a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="card " style="height: 150px">
              <div class="card-header pb-0 border-0 d-flex">
                <div class="flex">
                  <div class="h2 mb-0">GOtv</div>
                  <!--<p class="mb-0"><strong>Data</strong></p>-->
                </div>
                <!--<i class="material-icons text-50">screen_rotation</i>-->
                <img src="https://vtpass.com/resources/products/200X200/Gotv-Payment.jpg" width="40" height="40">
              </div>
              <div class="card-body">

                <div class="text-center col-auto border-left">
                  <a (click)="selectedTvType = tvType.GOTV; refreshDto()"
                     class="btn btn-accent">Buy now</a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="card " style="height: 150px">
              <div class="card-header pb-0 border-0 d-flex">
                <div class="flex">
                  <div class="h2 mb-0">Startimes</div>
                  <!--<p class="mb-0"><strong>Data</strong></p>-->
                </div>
                <!--<i class="material-icons text-50">screen_rotation</i>-->
                <img src="https://vtpass.com/resources/products/200X200/Startimes-Subscription.jpg" width="40" height="40">
              </div>
              <div class="card-body">

                <div class="text-center col-auto border-left">
                  <a (click)="selectedTvType = tvType.STARTIMES; refreshDto()"
                     class="btn btn-accent">Buy now</a>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>



      <div class="col-lg-12" *ngIf="isLoading1">
        <div style="max-width: 100%;" >
          <div class="loader-list" align="center">
            <div class="loader loader-primary"></div>
          </div>
        </div>
      </div>





      <!-----------DSTV------------>
      <div class="col-lg-12" *ngIf="selectedTvType == tvType.DSTV && !isLoading1">

        <div class="row align-items-center mb-8pt">

          <div class="col-md-6">
            <div class="card card--raised">
              <div class="card-header d-flex align-items-center">
                <div class="flex">
                  <h4 class="card-title">DSTV</h4>
                  <p class="card-subtitle">Awesome DSTV packages</p>
                </div>
                <img src="https://vtpass.com/resources/products/200X200/Pay-DSTV-Subscription.jpg" width="60" height="60">
              </div>
              <div class="card-body">
                <form #dstvForm="ngForm" novalidate (ngSubmit)="buyTv(tvType.DSTV)">


                  <div class="form-group">
                    <label class="form-label"
                           for="emailA">Decoder number</label>

                    <div class="input-group input-group-merge">
                      <input type="text"
                             class="form-control form-control-appended" required="" id="decoder1"
                             placeholder="Enter your decoder number" name="decoder1" #decoder1="ngModel"
                             [class.is-invalid]="decoder1.invalid && decoder1.touched"
                             [(ngModel)]="buyTvDto.decoderNo">
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="material-icons">tv</span>
                        </div>
                      </div>
                    </div>
                  </div>



                  <div class="form-group">
                    <label class="form-label" for="phoneA">Phone number</label>

                    <div class="input-group input-group-merge">
                      <input type="text"
                             class="form-control form-control-appended is-invalid" id="phoneA"
                             placeholder="Enter your phone number" name="phone" #phone="ngModel" required
                             [class.is-invalid]="phone.invalid && phone.touched"
                             [(ngModel)]="buyTvDto.phone">
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="material-icons">phone_android</span>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="form-group">
                    <label class="form-label"
                           for="emailA">Email address</label>

                    <div class="input-group input-group-merge">
                      <input type="email"
                             class="form-control form-control-appended" required="" id="emailA"
                             pattern="^[^@]+@[^@]+\.[^@]+$"
                             placeholder="Enter your email" name="email" #email="ngModel"
                             [class.is-invalid]="email.invalid && email.touched"
                             [(ngModel)]="buyTvDto.email">
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="material-icons">alternate_email</span>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="form-group">
                    <label class="form-label" for="variatnCode1">TV Plan</label>
                    <select required id="variatnCode1" name="variatnCode1" #variatnCode1="ngModel"
                            data-toggle="select" [(ngModel)]="buyTvDto.variationCode"
                            class="form-control">
                      <option *ngFor="let variatn of dstvVariations"
                              [value]="variatn.variationCode">
                        {{variatn.name}}
                      </option>
                    </select>
                  </div>


                  <button class="btn btn-accent" type="submit"
                          [class.is-loading]="isLoading2"
                          [disabled]="dstvForm.form.invalid || isLoading2">
                    BUY
                  </button>
                </form>
              </div>
            </div>

          </div>

        </div>

      </div>


      <!-----------GO TV------------>
      <div class="col-lg-12" *ngIf="selectedTvType == tvType.GOTV && !isLoading1">

        <div class="row align-items-center mb-8pt">

          <div class="col-md-6">
            <div class="card card--raised">
              <div class="card-header d-flex align-items-center">
                <div class="flex">
                  <h4 class="card-title">GOtv</h4>
                  <p class="card-subtitle">Awesome GOtv packages</p>
                </div>
                <img src="https://vtpass.com/resources/products/200X200/Gotv-Payment.jpg" width="60" height="60">
              </div>
              <div class="card-body">
                <form #gotvForm="ngForm" novalidate (ngSubmit)="buyTv(tvType.GOTV)">


                  <div class="form-group">
                    <label class="form-label"
                           for="emailA">Decoder number</label>

                    <div class="input-group input-group-merge">
                      <input type="text"
                             class="form-control form-control-appended" required="" id="decoder2"
                             placeholder="Enter your decoder number" name="decoder2" #decoder2="ngModel"
                             [class.is-invalid]="decoder2.invalid && decoder2.touched"
                             [(ngModel)]="buyTvDto.decoderNo">
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="material-icons">tv</span>
                        </div>
                      </div>
                    </div>
                  </div>



                  <div class="form-group">
                    <label class="form-label" for="phoneA">Phone number</label>

                    <div class="input-group input-group-merge">
                      <input type="text"
                             class="form-control form-control-appended is-invalid" id="phoneB"
                             placeholder="Enter your phone number" name="phoneB" #phoneB="ngModel" required
                             [class.is-invalid]="phoneB.invalid && phoneB.touched"
                             [(ngModel)]="buyTvDto.phone">
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="material-icons">phone_android</span>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="form-group">
                    <label class="form-label"
                           for="emailA">Email address</label>

                    <div class="input-group input-group-merge">
                      <input type="email"
                             class="form-control form-control-appended" required="" id="emailB"
                             pattern="^[^@]+@[^@]+\.[^@]+$"
                             placeholder="Enter your email" name="emailB" #emailB="ngModel"
                             [class.is-invalid]="emailB.invalid && emailB.touched"
                             [(ngModel)]="buyTvDto.email">
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="material-icons">alternate_email</span>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="form-group">
                    <label class="form-label" for="variatnCode2">TV Plan</label>
                    <select required id="variatnCode2" name="variatnCode2" #variatnCode2="ngModel"
                            data-toggle="select" [(ngModel)]="buyTvDto.variationCode"
                            class="form-control">
                      <option *ngFor="let variatn of gotvVariations"
                              [value]="variatn.variationCode">
                        {{variatn.name}}
                      </option>
                    </select>
                  </div>


                  <button class="btn btn-accent" type="submit"
                          [class.is-loading]="isLoading2"
                          [disabled]="gotvForm.form.invalid || isLoading2">
                    BUY
                  </button>
                </form>
              </div>
            </div>

          </div>

        </div>

      </div>


      <!-----------STARTIMES TV------------>
      <div class="col-lg-12" *ngIf="selectedTvType == tvType.STARTIMES && !isLoading1">

        <div class="row align-items-center mb-8pt">

          <div class="col-md-6">
            <div class="card card--raised">
              <div class="card-header d-flex align-items-center">
                <div class="flex">
                  <h4 class="card-title">Startimes</h4>
                  <p class="card-subtitle">Awesome Startimes packages</p>
                </div>
                <img src="https://vtpass.com/resources/products/200X200/Startimes-Subscription.jpg" width="60" height="60">
              </div>
              <div class="card-body">
                <form #startimeForm="ngForm" novalidate (ngSubmit)="buyTv(tvType.STARTIMES)">


                  <div class="form-group">
                    <label class="form-label"
                           for="emailA">Decoder number</label>

                    <div class="input-group input-group-merge">
                      <input type="text"
                             class="form-control form-control-appended" required="" id="decoder3"
                             placeholder="Enter your decoder number" name="decoder3" #decoder3="ngModel"
                             [class.is-invalid]="decoder3.invalid && decoder3.touched"
                             [(ngModel)]="buyTvDto.decoderNo">
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="material-icons">tv</span>
                        </div>
                      </div>
                    </div>
                  </div>



                  <div class="form-group">
                    <label class="form-label" for="phoneA">Phone number</label>

                    <div class="input-group input-group-merge">
                      <input type="text"
                             class="form-control form-control-appended is-invalid" id="phoneC"
                             placeholder="Enter your phone number" name="phoneC" #phoneC="ngModel" required
                             [class.is-invalid]="phoneC.invalid && phoneC.touched"
                             [(ngModel)]="buyTvDto.phone">
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="material-icons">phone_android</span>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="form-group">
                    <label class="form-label"
                           for="emailA">Email address</label>

                    <div class="input-group input-group-merge">
                      <input type="email"
                             class="form-control form-control-appended" required="" id="emailC"
                             pattern="^[^@]+@[^@]+\.[^@]+$"
                             placeholder="Enter your email" name="emailC" #emailC="ngModel"
                             [class.is-invalid]="emailC.invalid && emailC.touched"
                             [(ngModel)]="buyTvDto.email">
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="material-icons">alternate_email</span>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="form-group">
                    <label class="form-label" for="variatnCode3">TV Plan</label>
                    <select required id="variatnCode3" name="variatnCode3" #variatnCode3="ngModel"
                            data-toggle="select" [(ngModel)]="buyTvDto.variationCode"
                            class="form-control">
                      <option *ngFor="let variatn of starTimesVariations"
                              [value]="variatn.variationCode">
                        {{variatn.name}}
                      </option>
                    </select>
                  </div>


                  <button class="btn btn-accent" type="submit"
                          [class.is-loading]="isLoading2"
                          [disabled]="startimeForm.form.invalid || isLoading2">
                    BUY
                  </button>
                </form>
              </div>
            </div>

          </div>

        </div>

      </div>

    </div>

  </div>
</div>
