import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ProductService} from '../../../service/product.service';
import {BuyElectricityDto} from '../../../dto/buy-electricity-dto';
import {ServiceDetailsDto} from '../../../dto/service-details-dto';
import {HttpErrorResponse} from '@angular/common/http';
import {TvTypeEnum} from '../../../constant/tv-type-enum.enum';
import {TransactionSummaryDto} from '../../../entity/transaction-summary-dto';
declare function showToast(msg): any;

@Component({
  selector: 'app-electricity',
  templateUrl: './electricity.component.html',
  styleUrls: ['./electricity.component.css']
})
export class ElectricityComponent implements OnInit {

  public buyElectricityDto = new BuyElectricityDto();
  public serviceDetailsList: ServiceDetailsDto[] = [];

  public isLoading1 = true;
  public isLoading2 = false;

  constructor(public route: ActivatedRoute,
              public router: Router,
              public productService: ProductService) { }

  ngOnInit(): void {
    this.getAllElectricityServices();
  }

  getAllElectricityServices(): void {
    this.isLoading1 = true;

    this.productService.getAllElectricityServices()
      .subscribe(data => {
        if (data.data.length > 0) {
          data.data.forEach(s => {
            const sd = new ServiceDetailsDto();
            sd.mapToDto(s);
            this.serviceDetailsList.push(sd);
          });

          // set default disco
          this.buyElectricityDto.discoName = this.serviceDetailsList[0].serviceID;

          // set default meter type
          this.buyElectricityDto.meterType = 'prepaid';
        }

        this.isLoading1 = false;
      }, error1 => {
        this.isLoading1 = true;
        this.errorHandler(error1);
      });
  }



  buyElectricity(): void {

    this.isLoading2 = true;

    this.productService.initializeElectricityPurchase(this.buyElectricityDto)
      .subscribe(data => {
          const transactn = new TransactionSummaryDto();
          transactn.mapToEntity(data.data);
          localStorage.setItem('TRANSACTION_SUMMARY', JSON.stringify(transactn));

          this.isLoading2 = false;

          this.router.navigate(['/transaction-summary']);
          return;
        },
        error1 => {
          this.isLoading2 = false;
          this.errorHandler(error1);
        });

  }



  // Error handling
  public errorHandler(error) {

    if (error instanceof HttpErrorResponse) {

      if (error.error.message) {
        showToast(error.error.message);
        return;
      }

      showToast('Network error');
      return;
    }
    showToast('Network error');
    return;
  }

}
