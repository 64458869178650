import {AirtimeTransaction} from './airtime-transaction';
import {GeneralUtil} from '../util/general-util';
import {PortalService} from '../constant/portal-service';
import {InternetTransaction} from './internet-transaction';
import {TvTransaction} from './tv-transaction';
import {ElectricityTransaction} from './electricity-transaction';

export class TransactionSummaryDto {
  public serviceName: string;
  public transactionId: string;
  public airtimeTransaction = new AirtimeTransaction();
  public internetTransaction = new InternetTransaction();
  public tvTransaction = new TvTransaction();
  public electricityTransaction = new ElectricityTransaction();

  public mapToEntity(data: any): void
  {
    if (GeneralUtil.isValidJSON(data)) {
      this.serviceName = data.serviceName;
      this.airtimeTransaction.mapToEntity(data.airtimeTransaction);
      this.internetTransaction.mapToEntity(data.internetDataTransaction);
      this.tvTransaction.mapToEntity(data.tvTransaction);
      this.electricityTransaction.mapToEntity(data.electricityTransaction);


      // set transaction id
      if (this.serviceName === PortalService.AIRTIME.toString()) {
        this.transactionId = this.airtimeTransaction.transactionId;
      }
      if (this.serviceName === PortalService.DATA.toString()) {
        this.transactionId = this.internetTransaction.transactionId;
      }
      if (this.serviceName === PortalService.TV_SUBSCRIPTION.toString()) {
        this.transactionId = this.tvTransaction.transactionId;
      }
      if (this.serviceName === PortalService.ELECTRICITY) {
        this.transactionId = this.electricityTransaction.transactionId;
      }



    }
  }
}
