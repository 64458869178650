import {Component, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {TransactionService} from '../../../service/transaction.service';

declare function showToast(msg): any;

@Component({
  selector: 'app-a-dashboard',
  templateUrl: './a-dashboard.component.html',
  styleUrls: ['./a-dashboard.component.css']
})
export class ADashboardComponent implements OnInit {

  public isLoading = false;
  public stats = {
    successfulTransactionsToday: 0,
    failedOrNotPaidTransactionsToday: 0,
    walletRechargeToday: 0,
    totalUnconfirmedTransactions: 0,
    totalAirtimeTransactions: 0,
    totalDataTransactions: 0,
    totalTvTransactions: 0,
    totalElectricityTransactions: 0,
  };

  constructor(public transactionService: TransactionService) {
  }

  ngOnInit(): void {
    this.getStats();
  }

  getStats(): void {
    this.isLoading = true;


    this.transactionService.getAdminDashboardStats().subscribe(
      data => {
        this.stats.successfulTransactionsToday = data.data.successfulTransactionsToday;
        this.stats.failedOrNotPaidTransactionsToday = data.data.failedOrNotPaidTransactionsToday;
        this.stats.walletRechargeToday = data.data.walletRechargeToday;
        this.stats.totalUnconfirmedTransactions = data.data.totalUnconfirmedTransactions;
        this.stats.totalAirtimeTransactions = data.data.totalAirtimeTransactions;
        this.stats.totalDataTransactions = data.data.totalDataTransactions;
        this.stats.totalTvTransactions = data.data.totalTvTransactions;
        this.stats.totalElectricityTransactions = data.data.totalElectricityTransactions;

        this.isLoading = false;
      },
      error1 => {
        this.isLoading = false;
        this.errorHandler(error1);
      }
    );
  }


  // Error handling
  public errorHandler(error1) {

    if (error1 instanceof HttpErrorResponse) {

      if (error1.error.message) {
        showToast(error1.error.message);
        return;
      }

      showToast('Network error');
      return;
    }
    showToast('Network error');
    return;
  }

}
