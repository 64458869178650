import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {TransactionSummaryDto} from '../../entity/transaction-summary-dto';
import {NavigationEnd, NavigationStart, Router, RoutesRecognized} from '@angular/router';
import {filter, pairwise} from 'rxjs/operators';
import {GeneralUtil} from '../../util/general-util';
import {RouterExtService} from '../../service/router-ext.service';
import {Flutterwave, InlinePaymentOptions, PaymentSuccessResponse} from 'flutterwave-angular-v3';
import {ProductService} from '../../service/product.service';
import {PaymentGateway} from '../../constant/payment-gateway.enum';
import {ConfirmServicePurchaseDto} from '../../dto/confirm-service-purchase-dto';
import {HttpErrorResponse} from '@angular/common/http';
import {PortalService} from '../../constant/portal-service';
import {CustomerDetailDto} from '../../dto/customer-detail-dto';
import {PaymentService} from '../../service/payment.service';
import {PaymentGatewayService} from '../../service/payment-gateway.service';
import {PaymentGatewayEntity} from '../../entity/payment-gateway-entity';
import {AuthService} from '../../service/auth/auth.service';
import {RoleConstant} from '../../constant/role-constant.enum';
declare function showToast(msg): any;

@Component({
  selector: 'app-transaction-summary',
  templateUrl: './transaction-summary.component.html',
  styleUrls: ['./transaction-summary.component.css']
})
export class TransactionSummaryComponent implements OnInit {

  // variables
  public transactnSummaryDto = new TransactionSummaryDto();
  public paymentGateways: PaymentGatewayEntity[] = [];
  public PaymentGateway = PaymentGateway;
  public PortalService = PortalService;
  public isLoading = false;
  public isLoading2 = false;
  public notification = null;
  public paymentData: InlinePaymentOptions;
  public RoleConstant = RoleConstant;

  public showToken = false;
  public tokenDetails = {
    token: '',
    units: '',
    debtAmount: '',
    name: '',
    address: ''
  };


  constructor(public router: Router,
              public productService: ProductService,
              public paymentService: PaymentService,
              public flutterwave: Flutterwave,
              public authService: AuthService,
              public paymentGatewayServc: PaymentGatewayService,
              public cdf: ChangeDetectorRef) {

  }

  ngOnInit(): void {

    // get transaction summary
    this.transactnSummaryDto = JSON.parse(localStorage.getItem('TRANSACTION_SUMMARY'));


    if (this.transactnSummaryDto == null || !GeneralUtil.isValidString(this.transactnSummaryDto.serviceName)) {
      this.router.navigate(['/']);
      return;
    }

    this.getAllPaymentGateways();
  }

  getAllPaymentGateways(): void {
    this.paymentGatewayServc.getPaymentGateways()
      .subscribe(data => {
        if (data.data.length > 0) {
          data.data.forEach(p => {
            const pg = new PaymentGatewayEntity();
            pg.mapToEntity(p);
            this.paymentGateways.push(pg);
          });
        }
      }, error1 => this.errorHandler(error1));
  }


  payWithPaymentGateway(pg: PaymentGateway): void {
    this.isLoading = true;


    // confirm service purchase
    const confirmatnDto = new ConfirmServicePurchaseDto();
    confirmatnDto.paymentGatewayName = pg;
    confirmatnDto.portalServiceName = this.transactnSummaryDto.serviceName;
    confirmatnDto.transactionId = this.transactnSummaryDto.transactionId;

    this.productService.confirmServicePurchase(confirmatnDto)
      .subscribe(data => {

        switch (pg) {
          case PaymentGateway.PAYSTACK:
            showToast('Not available for now');
            return;

          case PaymentGateway.RAVEPAY:
            this.payWithRavePay(data.data.amount, data.data.transactionId);
            break;
        }


      }, error1 => {
        this.isLoading = false;
        this.errorHandler(error1);
      });
  }

  payWithRavePay(totalAmount: number, transactionId: string): void {

    // set up paymentData
    // 1.
    const pg = this.paymentGateways.filter(p => {
      return p.name === PaymentGateway.RAVEPAY;
    })[0];

    if (pg == null) {
      showToast('Rave payment not available at the moment');
      return;
    }


    const publicKey = pg.publicKey;

    const customizatns = {title: 'Now recharge',
      description: 'Easy payments for all utility bills',
      logo: 'https://flutterwave.com/images/logo-colored.svg'
    };            // TODO: change to nowrecharge logo && let it be dynamic

    const customerDetails = new CustomerDetailDto();

    switch (this.transactnSummaryDto.serviceName) {
      case (PortalService.AIRTIME.toString()):
        customerDetails.name = this.transactnSummaryDto.airtimeTransaction.email;
        customerDetails.email = this.transactnSummaryDto.airtimeTransaction.email;
        customerDetails.phone_number = this.transactnSummaryDto.airtimeTransaction.phoneNumber;

        break;

      case (PortalService.DATA.toString()):
        customerDetails.name = this.transactnSummaryDto.internetTransaction.email;
        customerDetails.email = this.transactnSummaryDto.internetTransaction.email;
        customerDetails.phone_number = this.transactnSummaryDto.internetTransaction.phoneNumber;
        break;

      case (PortalService.TV_SUBSCRIPTION.toString()):
        customerDetails.name = this.transactnSummaryDto.tvTransaction.email;
        customerDetails.email = this.transactnSummaryDto.tvTransaction.email;
        customerDetails.phone_number = this.transactnSummaryDto.tvTransaction.phoneNumber;
        break;

      case (PortalService.ELECTRICITY.toString()):
        customerDetails.name = this.transactnSummaryDto.electricityTransaction.email;
        customerDetails.email = this.transactnSummaryDto.electricityTransaction.email;
        customerDetails.phone_number = this.transactnSummaryDto.electricityTransaction.phoneNo;
        break;
    }



    // 2.
    this.paymentData = {
      public_key: publicKey,
      tx_ref: transactionId,
      amount: totalAmount,
      currency: 'NGN',
      payment_options: 'card,ussd',
      redirect_url: '',
      meta: '',
      customer: customerDetails,
      customizations: customizatns,
      callback: this.flutterWavePaymentCallback,
      onclose: this.closedPaymentModal,
      callbackContext: this
    };

    // go to payment gateway
    this.flutterwave.inlinePay(this.paymentData);
  }


  flutterWavePaymentCallback(response: PaymentSuccessResponse): void {
    this.flutterwave.closePaymentModal(2);

    // if payment is not successful
    if (response.status !== 'successful') {
      showToast('Your payment was NOT successful. Please try again');
      this.isLoading = false;
      return;
    }

    // if payment is successful, verify it
    this.paymentService.verifyPayment(response.tx_ref)
      .subscribe(data => {
        showToast(data.data.message);
        this.notification = data.data.message;

        if (this.transactnSummaryDto.serviceName == PortalService.ELECTRICITY
              && GeneralUtil.isValidString(data.data.others)) {
          this.showElectricityToken(data.data.others);
        }

        this.isLoading = false;
        this.cdf.detectChanges();

        // TODO: delete the transactnSummaryDto from localstorage
      }, error1 => {
        this.isLoading = false;
        this.cdf.detectChanges();
        this.errorHandler(error1);
      });
    this.cdf.detectChanges();
  }

  closedPaymentModal(): void {

    /*setTimeout(() => {
      this.isLoading = false;
      this.cdf.detectChanges();   // this will be used to detect changes and update the view
    }, 500);*/
  }


  showElectricityToken(data: any): void {
    if (GeneralUtil.isValidString(data.token) && GeneralUtil.isValidString(data.units)) {
      this.showToken = true;
      this.tokenDetails.token = data.token;
      this.tokenDetails.units = data.units;
      this.tokenDetails.name = data.name;
      this.tokenDetails.address = data.address;
      this.tokenDetails.debtAmount = data.debtAmount;
    }
  }


  payWithWalletInit(): void {
    this.isLoading2 = true;


    // confirm service purchase
    const confirmatnDto = new ConfirmServicePurchaseDto();
    confirmatnDto.payWithWallet = true;
    confirmatnDto.portalServiceName = this.transactnSummaryDto.serviceName;
    confirmatnDto.transactionId = this.transactnSummaryDto.transactionId;

    this.productService.confirmServicePurchase(confirmatnDto)
      .subscribe(data => {
        this.payWithWallet(this.transactnSummaryDto.transactionId);
      }, error1 => {
        this.isLoading2 = false;
        this.errorHandler(error1);
      });
  }

  payWithWallet(transactionId: string): void {
    this.paymentService.payWithWallet(transactionId)
      .subscribe(data => {
        showToast(data.data.message);
        this.notification = data.data.message;


        if (this.transactnSummaryDto.serviceName == PortalService.ELECTRICITY
                && GeneralUtil.isValidString(data.data.others)) {
          this.showElectricityToken(data.data.others);
        }

        this.isLoading2 = false;
      }, error1 => {
        this.isLoading2 = false;
        this.errorHandler(error1);
      });
  }


  public errorHandler(error) {

    if (error instanceof HttpErrorResponse) {

      if (error.error.message) {
        showToast(error.error.message);
        return;
      }

      showToast('Network error');
      return;
    }
    showToast('Network error');
    return;
  }

}
