<div class="sidebar sidebar-mini sidebar-dark sidebar-left"
     data-perfect-scrollbar><br><br><br><br>

  <!-- Navbar toggler -->
  <!--<a
    class="navbar-toggler navbar-toggler-custom w-auto d-flex flex-shrink-0 align-items-center justify-content-center"
    data-toggle="tooltip"
    data-title="Switch to Vertical App Layout"
    data-placement="right"
    data-boundary="window">
    <span class="material-icons">sync_alt</span>
  </a>-->

  <!--<div class="navbar-toggler navbar-toggler-custom w-auto d-flex flex-shrink-0 align-items-center justify-content-center">
    <span class="avatar-title rounded bg-accent">NR </span>
  </div>-->

  <!----SIDE MENU ITEMS----->
  <ul class="nav flex-column flex-nowrap flex-shrink-0 sidebar-menu sm-item-bordered"
      id="sidebar-mini-tabs"
      role="tablist">

    <li >
      <!--<a
        class="p-4pt d-flex align-items-center justify-content-center">
        <img width="32"
             height="32"
             class="rounded-circle mr-8pt"
             src="assets/images/people/50/guy-3.jpg"
             alt="account"/>
        <i class="material-icons text-black-20 icon-16pt">keyboard_arrow_down</i>
      </a>-->
    </li>

    <li class="sidebar-menu-item" *ngIf="authService.getUserDetailItem('USER-DETAILS-ROLE') == RoleConstant.ADMINISTRATOR"
        data-title="Enterprise"
        data-placement="right"
        data-container="body"
        data-boundary="window">
      <a class="sidebar-menu-button" style="color: white; "
         role="tab"
         (click)="showNestedMenu('ADMIN_MENU')"
         aria-selected="false">
        <i class="sidebar-menu-icon sidebar-menu-icon--left material-icons">accessibility</i>
        <span class="sidebar-menu-text">Dashboard</span>
      </a>
    </li>

    <li class="sidebar-menu-item" *ngIf="authService.getUserDetailItem('USER-DETAILS-ROLE') == RoleConstant.PORTAL_USER"
        data-title="Enterprise"
        data-placement="right"
        data-container="body"
        data-boundary="window">
      <a class="sidebar-menu-button" style="color: white; "
         role="tab"
         (click)="showNestedMenu('PORTAL_USER_MENU')"
         aria-selected="false">
        <i class="sidebar-menu-icon sidebar-menu-icon--left material-icons">accessibility</i>
        <span class="sidebar-menu-text">Dashboard</span>
      </a>
    </li>

    <li class="sidebar-menu-item"
        data-title="Dashboards"
        data-placement="right"
        data-boundary="window">
      <a class="sidebar-menu-button" style="color: white"
         data-toggle="tab"
         role="tab"
         (click)="showNestedMenu(PortalService.AIRTIME)"
         aria-controls="sm_dashboards"
         aria-selected="true">
        <i class="sidebar-menu-icon sidebar-menu-icon--left material-icons">screen_rotation</i>
        <span class="sidebar-menu-text" >Airtime</span>
      </a>
    </li>

    <li class="sidebar-menu-item"
        data-title="Enterprise"
        data-placement="right"
        data-container="body"
        data-boundary="window">
      <a class="sidebar-menu-button" style="color: white; "
         role="tab"
         (click)="showNestedMenu(PortalService.DATA)"
         aria-selected="false">
        <i class="sidebar-menu-icon sidebar-menu-icon--left material-icons">donut_large</i>
        <span class="sidebar-menu-text">Internet</span>
      </a>
    </li>

    <li class="sidebar-menu-item"
        data-title="Enterprise"
        data-placement="right"
        data-container="body"
        data-boundary="window">
      <a class="sidebar-menu-button" style="color: white; "
         role="tab"
         (click)="showNestedMenu(PortalService.TV_SUBSCRIPTION)"
         aria-selected="false">
        <i class="sidebar-menu-icon sidebar-menu-icon--left material-icons">reset_tv</i>
        <span class="sidebar-menu-text">TV Subscription</span>
      </a>
    </li>

    <li class="sidebar-menu-item"
        data-title="Enterprise"
        data-placement="right"
        data-container="body"
        data-boundary="window">
      <a class="sidebar-menu-button" style="color: white; "
         role="tab"
         (click)="showNestedMenu(PortalService.ELECTRICITY)"
         aria-selected="false">
        <i class="sidebar-menu-icon sidebar-menu-icon--left material-icons">lightbulb</i>
        <span class="sidebar-menu-text">Electricity</span>
      </a>
    </li>


  </ul>
</div>

<!----------------------POP-UP SIDE BAR----------------->
<div class="sidebar sidebar-light sidebar-left flex sidebar-secondary sidebar-p-t"
     data-parent="#sm1" data-perfect-scrollbar>

  <!--airtime -->
  <div *ngIf="nestMenuControls.airtime">
    <div class="sidebar-heading mt-3"><strong>Airtime</strong></div>

    <a routerLink="/services/airtime/airtel" class="dropdown-item d-flex align-items-center mb-3">

      <div class="avatar avatar-sm mr-8pt">
        <img src="https://vtpass.com/resources/products/200X200/Airtel-Airtime.jpg" width="40" height="40">
      </div>

      <small class="ml-4pt flex">
                                <span class="d-flex flex-column">
                                    <strong class="text-100">Airtel</strong>
                                  <!--<span class="text-50">Administrator</span>-->
                                </span>
      </small>
    </a>

    <a routerLink="/services/airtime/mtn" class="dropdown-item d-flex align-items-center mb-3">

      <div class="avatar avatar-sm mr-8pt">
        <img src="https://vtpass.com/resources/products/200X200/MTN-Airtime.jpg" width="40" height="40">
      </div>

      <small class="ml-4pt flex">
            <span class="d-flex flex-column">
                <strong class="text-100">MTN</strong>
            </span>
      </small>
    </a>

    <a routerLink="/services/airtime/glo" class="dropdown-item d-flex align-items-center mb-3">

      <div class="avatar avatar-sm mr-8pt">
        <img src="https://vtpass.com/resources/products/200X200/GLO-Airtime.jpg" width="40" height="40">
      </div>

      <small class="ml-4pt flex">
            <span class="d-flex flex-column">
                <strong class="text-100">Glo</strong>
            </span>
      </small>
    </a>

    <a routerLink="/services/airtime/etisalat" class="dropdown-item d-flex align-items-center mb-3">

      <div class="avatar avatar-sm mr-8pt">
        <img src="https://vtpass.com/resources/products/200X200/9mobile-Airtime.jpg" width="40" height="40">
      </div>

      <small class="ml-4pt flex">
            <span class="d-flex flex-column">
                <strong class="text-100">9mobile</strong>
            </span>
      </small>
    </a>
  </div>

  <!--data-->
  <div *ngIf="nestMenuControls.data">
    <div class="sidebar-heading mt-3"><strong>Internet / Data</strong></div>

    <a routerLink="/services/data/airtel-data" class="dropdown-item d-flex align-items-center mb-3">

      <div class="avatar avatar-sm mr-8pt">
        <img src="https://vtpass.com/resources/products/200X200/Airtel-Airtime.jpg" width="40" height="40">
      </div>

      <small class="ml-4pt flex">
                                <span class="d-flex flex-column">
                                    <strong class="text-100">Airtel Data</strong>
                                  <!--<span class="text-50">Administrator</span>-->
                                </span>
      </small>
    </a>

    <a routerLink="/services/data/mtn-data" class="dropdown-item d-flex align-items-center mb-3">

      <div class="avatar avatar-sm mr-8pt">
        <img src="https://vtpass.com/resources/products/200X200/MTN-Airtime.jpg" width="40" height="40">
      </div>

      <small class="ml-4pt flex">
            <span class="d-flex flex-column">
                <strong class="text-100">MTN Data</strong>
            </span>
      </small>
    </a>

    <a routerLink="/services/data/glo-data" class="dropdown-item d-flex align-items-center mb-3">

      <div class="avatar avatar-sm mr-8pt">
        <img src="https://vtpass.com/resources/products/200X200/GLO-Airtime.jpg" width="40" height="40">
      </div>

      <small class="ml-4pt flex">
            <span class="d-flex flex-column">
                <strong class="text-100">Glo Data</strong>
            </span>
      </small>
    </a>

    <a routerLink="/services/data/etisalat-data" class="dropdown-item d-flex align-items-center mb-3">

      <div class="avatar avatar-sm mr-8pt">
        <img src="https://vtpass.com/resources/products/200X200/9mobile-Airtime.jpg" width="40" height="40">
      </div>

      <small class="ml-4pt flex">
            <span class="d-flex flex-column">
                <strong class="text-100">9mobile Data</strong>
            </span>
      </small>
    </a>
  </div>

  <!--electricity-->
  <div *ngIf="nestMenuControls.electricity">
    <div class="sidebar-heading mt-3"><strong>Electricity</strong></div>

    <a routerLink="/services/electricity" *ngFor="let electricityService of  serviceDetailsList"
       class="dropdown-item d-flex align-items-center mb-3">

      <div class="avatar avatar-sm mr-8pt">
        <span class="avatar-title rounded bg-accent">{{electricityService.name | slice:0:2 | uppercase}} </span>
      </div>

      <small class="ml-4pt flex" style="font-size: xx-small">
                <span class="d-flex flex-column">
                    <strong class="text-100">{{electricityService.name | slice:0:30}}</strong>
                  <!--<span class="text-50">{{electricityService.name | slice:0:20}}</span>-->
                </span>
      </small>
    </a>
  </div>

  <!--tv-->
  <div *ngIf="nestMenuControls.tv">
    <div class="sidebar-heading mt-3"><strong>TV subscription</strong></div>

    <a routerLink="/services/tv/dstv" class="dropdown-item d-flex align-items-center mb-3">

      <div class="avatar avatar-sm mr-8pt">
        <img src="https://vtpass.com/resources/products/200X200/Pay-DSTV-Subscription.jpg" width="40" height="40">
      </div>

      <small class="ml-4pt flex">
          <span class="d-flex flex-column">
              <strong class="text-100">DSTV</strong>
          </span>
      </small>
    </a>

    <a routerLink="/services/tv/gotv" class="dropdown-item d-flex align-items-center mb-3">

      <div class="avatar avatar-sm mr-8pt">
        <img src="https://vtpass.com/resources/products/200X200/Gotv-Payment.jpg" width="40" height="40">
      </div>

      <small class="ml-4pt flex">
          <span class="d-flex flex-column">
              <strong class="text-100">Go Tv</strong>
          </span>
      </small>
    </a>

    <a routerLink="/services/tv/startimes" class="dropdown-item d-flex align-items-center mb-3">

      <div class="avatar avatar-sm mr-8pt">
        <img src="https://vtpass.com/resources/products/200X200/Startimes-Subscription.jpg" width="40" height="40">
      </div>

      <small class="ml-4pt flex">
                  <span class="d-flex flex-column">
                      <strong class="text-100">Startimes</strong>
                  </span>
      </small>
    </a>
  </div>

  <!--portal user menu-->
  <div *ngIf="nestMenuControls.portal_user_menu">
    <div class="sidebar-heading mt-3"><strong>Your Dashboard</strong></div>

    <a routerLink="/user-dashboard" class="dropdown-item d-flex align-items-center mb-3">

      <div class="avatar avatar-sm mr-8pt">
        <i class="material-icons">dashboard</i>
      </div>

      <small class="ml-4pt flex">
          <span class="d-flex flex-column">
              <strong class="text-100">Dashboard</strong>
          </span>
      </small>
    </a>

    <a routerLink="/user-dashboard/transactions" class="dropdown-item d-flex align-items-center mb-3">

      <div class="avatar avatar-sm mr-8pt">
        <i class="material-icons">receipt_long</i>
      </div>

      <small class="ml-4pt flex">
          <span class="d-flex flex-column">
              <strong class="text-100">Transactions</strong>
          </span>
      </small>
    </a>

    <a routerLink="/user-dashboard/wallet" class="dropdown-item d-flex align-items-center mb-3">

      <div class="avatar avatar-sm mr-8pt">
        <i class="material-icons">receipt_long</i>
      </div>

      <small class="ml-4pt flex">
          <span class="d-flex flex-column">
              <strong class="text-100">Fund wallet</strong>
          </span>
      </small>
    </a>

  </div>

  <!--admin menu-->
  <div *ngIf="nestMenuControls.admin_menu">
    <div class="sidebar-heading mt-3"><strong>Admin Dashboard</strong></div>

    <a routerLink="/admin-dashboard" class="dropdown-item d-flex align-items-center mb-3">

      <div class="avatar avatar-sm mr-8pt">
        <i class="material-icons">dashboard</i>
      </div>

      <small class="ml-4pt flex">
          <span class="d-flex flex-column">
              <strong class="text-100">Dashboard</strong>
          </span>
      </small>
    </a>

    <a routerLink="/admin-dashboard/transactions" class="dropdown-item d-flex align-items-center mb-3">

      <div class="avatar avatar-sm mr-8pt">
        <i class="material-icons">receipt_long</i>
      </div>

      <small class="ml-4pt flex">
          <span class="d-flex flex-column">
              <strong class="text-100">Transactions</strong>
          </span>
      </small>
    </a>


  </div>


</div>

