<div class="container page__container">
  <div class="page-section">

    <div class="row mb-lg-12pt">


      <!-------OVERVIEW OF NETWORKS-------->
      <div class="col-lg-12">

        <div class="page-separator">
          <div class="page-separator__text">Data</div>
        </div>

        <div class="row" *ngIf="selectedNetwork == 'all'">
          <div class="col-md-3">
            <div class="card " style="height: 150px">
              <div class="card-header pb-0 border-0 d-flex">
                <div class="flex">
                  <div class="h2 mb-0">Airtel</div>
                  <p class="mb-0"><strong>Data</strong></p>
                </div>
                <img src="https://vtpass.com/resources/products/200X200/Airtel-Airtime.jpg" width="40" height="40">
              </div>
              <div class="card-body">

                <div class="text-center col-auto border-left">
                  <a (click)="selectedNetwork = dataNetwork.AIRTEL_DATA; refreshDto()"
                     class="btn btn-accent">Buy now</a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="card " style="height: 150px">
              <div class="card-header pb-0 border-0 d-flex">
                <div class="flex">
                  <div class="h2 mb-0">Mtn</div>
                  <p class="mb-0"><strong>Data</strong></p>
                </div>
                <!--<i class="material-icons text-50">screen_rotation</i>-->
                <img src="https://vtpass.com/resources/products/200X200/MTN-Airtime.jpg" width="40" height="40">
              </div>
              <div class="card-body">

                <div class="text-center col-auto border-left">
                  <a (click)="selectedNetwork = dataNetwork.MTN_DATA; refreshDto()"
                     class="btn btn-accent">Buy now</a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="card " style="height: 150px">
              <div class="card-header pb-0 border-0 d-flex">
                <div class="flex">
                  <div class="h2 mb-0">Glo</div>
                  <p class="mb-0"><strong>Data</strong></p>
                </div>
                <!--<i class="material-icons text-50">screen_rotation</i>-->
                <img src="https://vtpass.com/resources/products/200X200/GLO-Airtime.jpg" width="40" height="40">
              </div>
              <div class="card-body">

                <div class="text-center col-auto border-left">
                  <a (click)="selectedNetwork = dataNetwork.GLO_DATA; refreshDto()"
                     class="btn btn-accent">Buy now</a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="card " style="height: 150px">
              <div class="card-header pb-0 border-0 d-flex">
                <div class="flex">
                  <div class="h2 mb-0">9Mobile</div>
                  <p class="mb-0"><strong>Data</strong></p>
                </div>
                <!--<i class="material-icons text-50">screen_rotation</i>-->
                <img src="https://vtpass.com/resources/products/200X200/9mobile-Airtime.jpg" width="40" height="40">
              </div>
              <div class="card-body">

                <div class="text-center col-auto border-left">
                  <a (click)="selectedNetwork = dataNetwork.ETISALAT_DATA; refreshDto()"
                     class="btn btn-accent">Buy now</a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>



      <div class="col-lg-12" *ngIf="isLoading1">
        <div style="max-width: 100%;" >
          <div class="loader-list" align="center">
            <div class="loader loader-primary"></div>
          </div>
        </div>
      </div>





      <!-----------AIRTEL DATA------------>
      <div class="col-lg-12" *ngIf="selectedNetwork == dataNetwork.AIRTEL_DATA && !isLoading1">

        <div class="row align-items-center mb-8pt">

          <div class="col-md-6">
            <div class="card card--raised">
              <div class="card-header d-flex align-items-center">
                <div class="flex">
                  <h4 class="card-title">Airtel</h4>
                  <p class="card-subtitle">Awesome airtel data plans</p>
                </div>
                <img src="https://vtpass.com/resources/products/200X200/Airtel-Airtime.jpg" width="60" height="60">
              </div>
              <div class="card-body">
                <form #airtelForm="ngForm" novalidate (ngSubmit)="buyData(dataNetwork.AIRTEL_DATA)">

                  <div class="form-group">
                    <label class="form-label" for="phoneA">Phone number</label>

                    <div class="input-group input-group-merge">
                      <input type="text"
                             class="form-control form-control-appended is-invalid" id="phoneA"
                             placeholder="Enter your phone number" name="phone" #phone="ngModel" required
                             [class.is-invalid]="phone.invalid && phone.touched"
                             [(ngModel)]="buyDataDto.phone">
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="material-icons">phone_android</span>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="form-group">
                    <label class="form-label"
                           for="emailA">Email address</label>

                    <div class="input-group input-group-merge">
                      <input type="email"
                             class="form-control form-control-appended" required="" id="emailA"
                             pattern="^[^@]+@[^@]+\.[^@]+$"
                             placeholder="Enter your email" name="email" #email="ngModel"
                             [class.is-invalid]="email.invalid && email.touched"
                             [(ngModel)]="buyDataDto.email">
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="material-icons">alternate_email</span>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="form-group">
                    <label class="form-label" for="variatnCode1">Data plan</label>
                    <select required id="variatnCode1" name="variatnCode1" #variatnCode1="ngModel"
                            data-toggle="select" [(ngModel)]="buyDataDto.variationCode"
                            class="form-control">
                      <option *ngFor="let dataVariatn of airtelDataVariations"
                              [value]="dataVariatn.variationCode">
                        {{dataVariatn.name}}
                      </option>
                    </select>
                  </div>


                  <button class="btn btn-accent" type="submit"
                          [class.is-loading]="isLoading2"
                          [disabled]="airtelForm.form.invalid || isLoading2">
                    BUY
                  </button>
                </form>
              </div>
            </div>

          </div>

        </div>

      </div>


      <!-----------MTN DATA------------>
      <div class="col-lg-12" *ngIf="selectedNetwork == dataNetwork.MTN_DATA && !isLoading1">

        <div class="row align-items-center mb-8pt">
          <div class="col-md-6">
            <div class="card card--raised">
              <div class="card-header d-flex align-items-center">
                <div class="flex">
                  <h4 class="card-title">MTN</h4>
                  <p class="card-subtitle">Awesome MTN data plans</p>
                </div>
                <img src="https://vtpass.com/resources/products/200X200/MTN-Airtime.jpg" width="60" height="60">
              </div>
              <div class="card-body">
                <form #mtnForm="ngForm" novalidate (ngSubmit)="buyData(dataNetwork.MTN_DATA)">

                  <div class="form-group">
                    <label class="form-label" for="phoneA">Phone number</label>

                    <div class="input-group input-group-merge">
                      <input type="text"
                             class="form-control form-control-appended is-invalid" id="phoneM"
                             placeholder="Enter your phone number" name="phoneM" #phoneM="ngModel" required
                             [class.is-invalid]="phoneM.invalid && phoneM.touched"
                             [(ngModel)]="buyDataDto.phone">
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="material-icons">phone_android</span>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="form-group">
                    <label class="form-label"
                           for="emailA">Email address</label>

                    <div class="input-group input-group-merge">
                      <input type="email"
                             class="form-control form-control-appended" id="emailM"
                             pattern="^[^@]+@[^@]+\.[^@]+$"
                             placeholder="Enter your email address" name="emailM" #emailM="ngModel" required
                             [class.is-invalid]="emailM.invalid && emailM.touched"
                             [(ngModel)]="buyDataDto.email">
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="material-icons">alternate_email</span>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="form-group">
                    <label class="form-label" for="variatnCode2">Data plan</label>
                    <select required id="variatnCode2" name="variatnCode2" #variatnCode2="ngModel"
                            data-toggle="select" [(ngModel)]="buyDataDto.variationCode"
                            class="form-control">
                      <option *ngFor="let dataVariatn of mtnDataVariations"
                              [value]="dataVariatn.variationCode">
                        {{dataVariatn.name}}
                      </option>
                    </select>
                  </div>


                  <button class="btn btn-accent"
                          [class.is-loading]="isLoading2"
                          type="submit" [disabled]="mtnForm.form.invalid || isLoading2">
                    BUY
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>

      </div>


      <!-----------GLO DATA------------>
      <div class="col-lg-12" *ngIf="selectedNetwork == dataNetwork.GLO_DATA && !isLoading1" >

        <div class="row align-items-center mb-8pt">
          <div class="col-md-6">
            <div class="card card--raised">
              <div class="card-header d-flex align-items-center">
                <div class="flex">
                  <h4 class="card-title">GLO</h4>
                  <p class="card-subtitle">Awesome GLO data plans</p>
                </div>
                <img src="https://vtpass.com/resources/products/200X200/GLO-Airtime.jpg" width="60" height="60">
              </div>
              <div class="card-body">
                <form #gloForm="ngForm" novalidate (ngSubmit)="buyData(dataNetwork.GLO_DATA)">

                  <div class="form-group">
                    <label class="form-label" for="phoneA">Phone number</label>

                    <div class="input-group input-group-merge">
                      <input type="text"
                             class="form-control form-control-appended is-invalid" id="phoneG"
                             placeholder="Enter your phone number" name="phoneG" #phoneG="ngModel" required
                             [class.is-invalid]="phoneG.invalid && phoneG.touched"
                             [(ngModel)]="buyDataDto.phone">
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="material-icons">phone_android</span>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="form-group">
                    <label class="form-label"
                           for="emailA">Email address</label>

                    <div class="input-group input-group-merge">
                      <input type="email"
                             class="form-control form-control-appended" required="" id="emailG"
                             pattern="^[^@]+@[^@]+\.[^@]+$"
                             placeholder="Enter your email address" name="emailG" #emailG="ngModel"
                             [class.is-invalid]="emailG.invalid && emailG.touched"
                             [(ngModel)]="buyDataDto.email">
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="material-icons">alternate_email</span>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="form-group">
                    <label class="form-label" for="variatnCode3">Data plan</label>
                    <select required id="variatnCode3" name="variatnCode3" #variatnCode3="ngModel"
                            data-toggle="select" [(ngModel)]="buyDataDto.variationCode"
                            class="form-control">
                      <option *ngFor="let dataVariatn of gloDataVariations"
                              [value]="dataVariatn.variationCode">
                        {{dataVariatn.name}}
                      </option>
                    </select>
                  </div>


                  <button class="btn btn-accent" [class.is-loading]="isLoading2"
                          type="submit" [disabled]="gloForm.form.invalid || isLoading2">
                    BUY
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>

      </div>


      <!-----------9MOBILE DATA------------>
      <div class="col-lg-12" *ngIf="selectedNetwork == dataNetwork.ETISALAT_DATA && !isLoading1">

        <div class="row align-items-center mb-8pt">
          <div class="col-md-6">
            <div class="card card--raised">
              <div class="card-header d-flex align-items-center">
                <div class="flex">
                  <h4 class="card-title">9MOBILE</h4>
                  <p class="card-subtitle">Awesome Etisalat data plans</p>
                </div>
                <img src="https://vtpass.com/resources/products/200X200/9mobile-Airtime.jpg" width="60" height="60">
              </div>
              <div class="card-body">
                <form #nineMobileForm="ngForm" novalidate (ngSubmit)="buyData(dataNetwork.ETISALAT_DATA)">

                  <div class="form-group">
                    <label class="form-label" for="phoneA">Phone number</label>

                    <div class="input-group input-group-merge">
                      <input type="text"
                             class="form-control form-control-appended is-invalid" id="phone9"
                             placeholder="Enter your phone number" name="phone9" #phone9="ngModel" required
                             [class.is-invalid]="phone9.invalid && phone9.touched"
                             [(ngModel)]="buyDataDto.phone">
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="material-icons">phone_android</span>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="form-group">
                    <label class="form-label"
                           for="emailA">Email address</label>

                    <div class="input-group input-group-merge">
                      <input type="email"
                             class="form-control form-control-appended" required="" id="email9"
                             pattern="^[^@]+@[^@]+\.[^@]+$"
                             placeholder="Enter your email address" name="email9" #email9="ngModel"
                             [class.is-invalid]="email9.invalid && email9.touched"
                             [(ngModel)]="buyDataDto.email">
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="material-icons">alternate_email</span>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="form-group">
                    <label class="form-label" for="variatnCode4">Data plan</label>
                    <select required id="variatnCode4" name="variatnCode4" #variatnCode4="ngModel"
                            data-toggle="select" [(ngModel)]="buyDataDto.variationCode"
                            class="form-control">
                      <option *ngFor="let dataVariatn of etisalatDataVariations"
                              [value]="dataVariatn.variationCode">
                        {{dataVariatn.name}}
                      </option>
                    </select>
                  </div>


                  <button class="btn btn-accent" type="submit" [class.is-loading]="isLoading2"
                          [disabled]="nineMobileForm.form.invalid || isLoading2">
                    BUY
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>

  </div>
</div>
