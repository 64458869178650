

<div class="container page__container">
  <div class="page-section">


    <div class="col-lg-12" *ngIf="isLoading">
      <div style="max-width: 100%;" >
        <div class="loader-list" align="center">
          <div class="loader loader-primary"></div>
        </div>
      </div>
    </div>

    <!---------------STATS------------------->
    <div class="page-separator">
      <div class="page-separator__text">Overview</div>
    </div>

    <div class="row card-group-row mb-lg-8pt">
      <div class="col-xl-3 col-md-6 card-group-row__col">
        <div class="card card-group-row__card">
          <div class="card-body d-flex flex-column align-items-center">
            <i class="material-icons icon-32pt text-20 mb-4pt">screen_rotation</i>
            <div class="d-flex align-items-center">
              <div class="h2 mb-0 mr-3">{{stats.airtimeCount}}</div>
              <div class="flex">
                <p class="mb-0"><strong>Airtime</strong></p>
                <p class="text-50 mb-0 mt-n1 d-flex align-items-center">
                  transactions
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-6 card-group-row__col">
        <div class="card card-group-row__card">
          <div class="card-body d-flex flex-column align-items-center">
            <i class="material-icons icon-32pt text-20 mb-4pt">donut_large</i>
            <div class="d-flex align-items-center">
              <div class="h2 mb-0 mr-3">{{stats.dataCount}}</div>
              <div class="flex">
                <p class="mb-0"><strong>Data</strong></p>
                <p class="text-50 mb-0 mt-n1 d-flex align-items-center">
                  transactions
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-6 card-group-row__col">
        <div class="card card-group-row__card">
          <div class="card-body d-flex flex-column align-items-center">
            <i class="material-icons icon-32pt text-20 mb-4pt">reset_tv</i>
            <div class="d-flex align-items-center">
              <div class="h2 mb-0 mr-3">{{stats.tvCount}}</div>
              <div class="flex">
                <p class="mb-0"><strong>TV</strong></p>
                <p class="text-50 mb-0 mt-n1 d-flex align-items-center">
                  transactions
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-6 card-group-row__col">
        <div class="card card-group-row__card">
          <div class="card-body d-flex flex-column align-items-center">
            <i class="material-icons icon-32pt text-20 mb-4pt">lightbulb</i>
            <div class="d-flex align-items-center">
              <div class="h2 mb-0 mr-3">{{stats.electricityCount}}</div>
              <div class="flex">
                <p class="mb-0"><strong>Electricity</strong></p>
                <p class="text-50 mb-0 mt-n1 d-flex align-items-center">
                  transactions
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!---------------MENU------------------->
    <div class="page-separator">
      <div class="page-separator__text">Menu</div>
    </div>

    <div class="row card-group-row mb-lg-8pt" routerLink="/user-dashboard/transactions">
      <div class="col-lg-4 card-group-row__col">

        <div class="card card-group-row__card">
          <div class="card-header py-12pt d-flex align-items-center">
            <div class="position-relative mr-16pt">
              <div class="text-center fullbleed d-flex align-items-center justify-content-center flex-column z-0">
                <div class="avatar avatar-sm mr-8pt">
                  <i class="material-icons">receipt_long</i>
                </div>
              </div>
              <canvas width="48"
                      height="48"
                      class="chart-canvas position-relative z-1"
                      id="openProgressChart"
                      data-chart-line-background-color="yellow;gray"
                      data-chart-disable-tooltips="true"></canvas>
            </div>
            <strong class="flex">View all transactions</strong>
            <div class="avatar avatar-sm mr-8pt">
              <i class="material-icons">arrow_forward</i>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 card-group-row__col" routerLink="/user-dashboard/wallet">
        <div class="card card-group-row__card">
          <div class="card-header py-12pt d-flex align-items-center">
            <div class="position-relative mr-16pt">
              <div class="text-center fullbleed d-flex align-items-center justify-content-center flex-column z-0">
                <div class="avatar avatar-sm mr-8pt">
                  <i class="material-icons">account_balance_wallet</i>
                </div>
              </div>
              <canvas width="48"
                      height="48"
                      class="chart-canvas position-relative z-1 js-update-chart-progress-accent"
                      id="inProgressChart"
                      data-chart-line-background-color="accent;gray"
                      data-chart-disable-tooltips="true"></canvas>
            </div>
            <strong class="flex">Fund wallet</strong>
            <div class="avatar avatar-sm mr-8pt">
              <i class="material-icons">arrow_forward</i>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</div>
