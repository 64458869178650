<div class=" pt-32pt pt-sm-64pt pb-32pt">
  <div class="container-fluid page__container">
    <form #rForm="ngForm" novalidate (ngSubmit)="resendAction()" class="col-md-5 p-0 mx-auto">
      <div class="form-group">

        <label class="form-label" for="email">To resend an email verification mail, please enter your email below:</label>

        <input id="email" type="email" class="form-control" name="email" required
               #email="ngModel" [(ngModel)]="email1" pattern="^[^@]+@[^@]+\.[^@]+$"
               [class.is-invalid]="email.invalid && email.touched"
               placeholder="Your email address ...">
      </div>


      <div class="text-center">
        <button class="btn btn-accent" type="submit"
                [class.is-loading]="isLoading"
                [disabled]="rForm.invalid">
          Resend email verification
        </button>
      </div>
    </form>
  </div>
</div>
