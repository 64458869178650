import {PaymentGateway} from '../constant/payment-gateway.enum';
import {PaymentGatewayEntity} from './payment-gateway-entity';
import {PortalService} from './portal-service';
import {PortalUser} from './portal-user';
import {GeneralUtil} from '../util/general-util';

export class ServiceTransaction {
  public id: number;
  public transactionId: string;
  public totalFee: number;
  public convinienceCharge: number;
  public regDate: string;
  public executionDate: string;
  public paymentStatus: string;
  public transactionDescription: string;
  public transactionStatus: string;
  public pspReference: string;
  public pspPayload: string;
  public valueStatus: string;
  public commission: string;
  public paymentGateway = new PaymentGatewayEntity();
  public portalService = new PortalService();
  public portalUser = new PortalUser();


  public mapToEntity(data: any): void
  {
    if (GeneralUtil.isValidJSON(data)) {
      this.id = data.id;
      this.transactionId = data.transactionId;
      this.totalFee = data.totalFee;
      this.convinienceCharge = data.convinienceCharge;
      this.regDate = data.regDate ? GeneralUtil.getDateTime(data.regDate.timestamp) : '';
      this.executionDate = data.executionDate ? GeneralUtil.getDateTime(data.executionDate.timestamp) : '';
      this.paymentStatus = GeneralUtil.removeUnderscore(data.paymentStatus);
      this.transactionDescription = data.transactionDescription;
      this.transactionStatus = GeneralUtil.removeUnderscore(data.transactionStatus);
      this.pspReference = data.pspReference;
      this.pspPayload = data.pspPayload;
      this.valueStatus = GeneralUtil.removeUnderscore(data.valueStatus);
      this.commission = data.commission;

      this.paymentGateway.mapToEntity(data.paymentgateway);
      this.portalService.mapToEntity(data.portalService);
      this.portalUser.mapToEntity(data.portalUser);
    }
  }


}
