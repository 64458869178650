<!-- Header -->
<div class="navbar navbar-expand navbar-shadow px-0  pl-lg-16pt navbar-light bg-body"
     id="default-navbar"
     data-primary>

  <!-- Navbar toggler -->
  <!--<button class="navbar-toggler d-block d-lg-none rounded-0"
          type="button"
          data-toggle="sidebar">
    <span class="material-icons">menu</span>
  </button>-->


  <!---------MOBILE SIDE MENU------------->
  <div class="dropdown border-left-2 navbar-border">
    <button class="navbar-toggler navbar-toggler-custom d-block d-lg-none rounded-0"
            type="button"
            data-toggle="dropdown"
            data-caret="false">
      <span class="material-icons">menu</span>
    </button>
    <div class="dropdown-menu dropdown-menu-left">
      <div class="dropdown-header"><strong>Choose a service</strong></div>


      <a routerLink="/user-dashboard" *ngIf="authService.getUserDetailItem('USER-DETAILS-ROLE') == RoleConstant.PORTAL_USER"
         class="dropdown-item d-flex align-items-center">

        <div class="avatar avatar-sm mr-8pt">
          <span class="avatar-title rounded bg-primary">
            <i class="sidebar-menu-icon material-icons">accessibility</i>
          </span>
        </div>

        <small class="ml-4pt flex">
            <span class="d-flex flex-column">
                <strong class="text-black-100">Dashboard</strong>
                <!--<span class="text-black-50">for all networks</span>-->
            </span>
        </small>
      </a>

      <a routerLink="/services/airtime/all" class="dropdown-item d-flex align-items-center">

        <div class="avatar avatar-sm mr-8pt">
          <span class="avatar-title rounded bg-primary">
            <i class="sidebar-menu-icon material-icons">screen_rotation</i>
          </span>
        </div>

        <small class="ml-4pt flex">
            <span class="d-flex flex-column">
                <strong class="text-black-100">Airtime</strong>
                <span class="text-black-50">for all networks</span>
            </span>
        </small>
      </a>

      <a routerLink="/services/data/all" class="dropdown-item d-flex align-items-center">

        <div class="avatar avatar-sm mr-8pt">
          <span class="avatar-title rounded bg-accent">
            <i class="sidebar-menu-icon material-icons">donut_large</i>
          </span>
        </div>

        <small class="ml-4pt flex">
                                    <span class="d-flex flex-column">
                                        <strong class="text-black-100">Data</strong>
                                        <span class="text-black-50">for all networks</span>
                                    </span>
        </small>
      </a>

      <a routerLink="/services/tv/all" class="dropdown-item d-flex align-items-center">

        <div class="avatar avatar-sm mr-8pt">
          <span class="avatar-title rounded bg-accent-electric-violet">
            <i class="sidebar-menu-icon material-icons">reset_tv</i>
          </span>
        </div>

        <small class="ml-4pt flex">
            <span class="d-flex flex-column">
                <strong class="text-black-100">TV subscription</strong>
                <span class="text-black-50"></span>
            </span>
        </small>
      </a>

      <a routerLink="/services/electricity" class="dropdown-item d-flex align-items-center">

        <div class="avatar avatar-sm mr-8pt">

          <span class="avatar-title rounded bg-accent-dodger-blue">
            <i class="sidebar-menu-icon material-icons">lightbulb</i>
          </span>

        </div>

        <small class="ml-4pt flex">
            <span class="d-flex flex-column">
                <strong class="text-black-100">Electricity</strong>
                <span class="text-black-50"></span>
            </span>
        </small>
      </a>
    </div>
  </div>

  <!-- Navbar Brand -->
  <a routerLink="/"
     class="navbar-brand mr-16pt">
    <img class="navbar-brand-icon mr-0 mr-lg-8pt"
         src="assets/images/logo/accent-teal-100@2x.png"
         width="32"
         alt="Huma">
    <span class="d-none d-lg-block">Now Recharge</span>
  </a>

  <!-- <button class="btn navbar-btn mr-16pt ml-md-16pt" data-toggle="modal" data-target="#apps">Apps <i class="material-icons">arrow_drop_down</i></button> -->


  <div class="flex"></div>


  <div class="nav navbar-nav flex-nowrap d-flex ml-0 mr-16pt">

    <div class="nav-item dropdown d-sm-flex" *ngIf="authService.isLoggedIn()">
      <a href="#"
         class="nav-link d-flex align-items-center dropdown-toggle" data-toggle="dropdown">
        <img width="32"
             height="32"
             class="rounded-circle mr-8pt"
             src="assets/images/people/50/guy-3.jpg"
             alt="account"/>
        <span class="flex d-flex flex-column mr-8pt">
            <span class="navbar-text-100 smaller">{{authService.getUserDetailItem('USER-DETAILS-FULL-NAME') | slice: 0:15}}</span>
            <!--<small class="navbar-text-50">Administrator</small>-->
        </span>
      </a>
      <div class="dropdown-menu dropdown-menu-right">
        <a routerLink="/user-dashboard" class="dropdown-item">Dashboard</a>
        <a class="dropdown-item">Profile</a>
        <a class="dropdown-item" (click)="logoutAction()">Logout</a>
      </div>
    </div>

    <!-- Login -->
    <div class="nav-item ml-16pt dropdown dropdown-notifications" *ngIf="!authService.isLoggedIn()">
      <button class="nav-link btn-flush dropdown-toggle"
              type="button"
              routerLink="/login"
              data-dropdown-disable-document-scroll
              data-caret="false">
        LOGIN
      </button>
    </div>
  </div>

</div>



