<div class="container page__container"><br><br>

  <div class="col-lg-12" *ngIf="isLoading1">
    <div style="max-width: 100%;" >
      <div class="loader-list" align="center">
        <div class="loader loader-primary"></div>
      </div>
    </div>
  </div>

  <div class="hero text-center pb-64pt" *ngIf="isValidated">
    <h1>Nice!</h1>
    <p class="text-50 lead measure-hero-lead mx-auto">Your email is successfully validated.
      Please click <a routerLink="/login">here</a> to login</p>
  </div>


  <div class="hero text-center pb-64pt" *ngIf="errorInValidation">
    <h1>Oops!</h1>
    <p class="text-50 lead measure-hero-lead mx-auto">{{validationErrorMsg}}</p>
  </div>
</div>
